import React from 'react'
import {Box, Typography, CardContent, Card, Avatar, CardHeader,
        List, ListItem, ListItemAvatar, ListItemText, Divider,
        ThumbUp as ThumbUpIcon, NewspaperSharp as NewspaperSharpIcon,
        blue} from '../../utils/AllImportsHelper';
import NewsAndUpdatesDummyData from '../../datafiles/dashboard/newsAndUpdatesDummyData.json';
import { styled } from "@mui/material/styles";

const ListItemTheme = styled(ListItem)(({ theme }) => ({
  paddingTop: '0',
  paddingBottom: '0',
  //  margin: '2px',
  // '&:hover': {
  //   backgroundColor: 'var(--color-lightblue)', /* theme.palette.action.hover, */
  //   borderRadius: '5px',
  //   //  margin: '2px',
  // },
  // '& .MuiListItemAvatar-root': {
  //   minWidth: '42px',
  // },
}));

const NewsUpdates = () => {
    const [dense, setDense] = React.useState(false);
  return (
    <>
         <Card sx={{  minHeight: 'var(--min-height-dashboard-components )', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardHeader sx={{ p:'var(--padding-all-1)' }}
              avatar={
                <Avatar>
                  <NewspaperSharpIcon fontSize="small" />
                </Avatar>

              }
              title={<Typography variant="h6">News & Updates</Typography>}
            // subheader="September 14, 2016"
            />
            <CardContent sx={{ p: 'var(--padding-none)' }}>
              <Box id='NewsAndUpdateData' className='news-update'>
                {NewsAndUpdatesDummyData.map((key, index) => {
                  return (
                    // <Card key={index}>
                    <List dense={dense} key={index} sx={{pt:0,pb:0}}>
                      <ListItemTheme>
                        <ListItemAvatar><img className="newsupdate-avatar" src={key.src} alt="" /></ListItemAvatar>
                        <ListItemText   
                            primary={<Typography variant="subtitle2">{key.title}</Typography>} 
                            secondary={key.desc} />
                      </ListItemTheme>
                      <ListItem 
                        secondaryAction={<ListItemText edge="end" 
                            secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>{key.time}</Typography>} />}>
                        <ListItemAvatar></ListItemAvatar>{/**/}
                        <ThumbUpIcon fontSize='xs' /> 
                        <ListItemText 
                          primary={<Typography sx={{ml:'var(--margin-small)'}} variant="caption">{key.likes}</Typography>}
                           />
                      </ListItem>
                      <Divider />
                    </List>
                    // </Card>
                  )
                })}
              </Box>
            </CardContent>
          </Card>
    </>
  )
}

export default NewsUpdates