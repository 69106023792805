import { createSlice } from '@reduxjs/toolkit';
import dashboard from '../../pages/Dashboard'



const routingSlice = createSlice({
    name: 'routing',
    initialState: {
      currentRoute: "/dashboard",
    },
    reducers: {
      setCurrentRoute: (state, action) => {
        state.currentRoute = action.payload;
      },
    },
  });
  
  export const { setCurrentRoute,} = routingSlice.actions;
  export default routingSlice.reducer;