import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const FamilyDetails = () => {

  let familyDetailsModel = [
    { label: 'Family Member Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
    { label: 'Relation', type: "singleSelect", value: '', validators:[ { name: 'required'} ], data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
    // , {name:'pattern', regex:/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ,errorMessage:'correct format: mm/dd/yyyy'}
    { label: 'Date Of Birth', type: "dateField", value: '', validators: [{ name:'required'}] },
    { label: 'Dependent (Yes/No)', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Whether Employed', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Employee Code (Optional)', type: "textField", validators:[ { name: ''} ], size: 'small', value: '' },
  ];

  let familyDetailsHelper = new FormHelper({ model: familyDetailsModel, id: 'familyDetails' });


    //  const familyMemberDetails = { 'familyMemberName': '', 'relation': '', 'dateOfBirth': '', 'dependent': '', 'whetherEmployed': '', 'employeeCode': '' };
     const [membersFamily, setMembersFamily] = useState([ new FormHelper({ model: familyDetailsModel, id: new Date().getTime() })]);
     
   
     const [isTouched, setIsTouched] = React.useState(false);
     const [isFilled, setIsFilled] = React.useState(false);

     familyDetailsHelper.isTouched.touched.subscribe(() => {
          setIsTouched(familyDetailsHelper.isTouched.touched.get());
    })

      familyDetailsHelper.isFilled.filled.subscribe(() => {
        setIsFilled(familyDetailsHelper.isFilled.filled.get());
      })

     const addNewFamily = () => {
      setMembersFamily([...membersFamily, new FormHelper({ model: familyDetailsModel, id: new Date().getTime() })]);
      familyDetailsHelper.isFilled.filled.set(false)
      setIsFilled(false)
      }

      const removeFamily = (i) => {
        let familyMembers = [...membersFamily];
        familyMembers.splice(i,1);
        if (!familyMembers.length)
          familyMembers.push( new FormHelper({ model: familyDetailsModel, id: new Date().getTime() }));
        setMembersFamily(familyMembers);
      }

     return(
     <>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
            {membersFamily.map((v, i) => {
              return <Box key={v.model.id} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
              <Box sx={{ gridColumn: 'span 3' }} id={v + "jds"}>
                <Grid container spacing={2}>
                  <Grid sx={{display:'flex', alignContent:'center', flexWrap:'wrap'}} item xs={10}>
                    <Typography  variant='h8' fontWeight={800}>Member {i+1}</Typography>
                  </Grid>
                  
                  <Grid item xs={2} className="dashboardStyle">
                      <IconButton aria-label="Example" onClick={() => removeFamily(i)}  disabled={!isTouched}>
                        <DeleteIcon icon={faEllipsisV}  size="small" />
                      </IconButton>
                  </Grid>
                </Grid>
                </Box>
                <RenderHelper {...familyDetailsHelper.model}/>
              </Box>
            })}

            <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
              <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewFamily} disabled={!isFilled}>
                Add Member
              </Button>
            </Box>
        </Box>
     </>
     );
}

export default FamilyDetails