import React from 'react'
import {Box, Typography, CardContent, Card, Avatar, CardHeader, AvatarGroup,
        List, ListItem, ListItemText, Button,
        EventSharp as EventSharpIcon,
        blue} from '../../utils/AllImportsHelper';
import UpcomingEventsDummyData from '../../datafiles/dashboard/upcomingEventsDummyData.json';

const UpcomingEvent = () => {
    const [dense, setDense] = React.useState(false);
  return (
    <>
          <Card sx={{ minHeight: 'var(--min-height-dashboard-components)', height:'100%', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardHeader sx={{ p:'var(--padding-all-1)' }}
              avatar={
                <Avatar>
                  <EventSharpIcon fontSize="small" />
                </Avatar>
              }
              title={<Typography variant="h6">Upcoming Events</Typography>}
            // subheader="September 14, 2016"
            />
            <CardContent sx={{ padding:'var(--padding-horizontal-1)'}}>
              <Box id='UpcomingEventsData' className='upcoming-events'>
                {UpcomingEventsDummyData.map((key, index) => {
                  return (
                    <Card key={index} className="event-card-style" sx={{ backgroundColor: key.bgColor }}>
                      <List dense={dense}>
                        <ListItem secondaryAction={<ListItemText edge="end" secondary={<Button variant="contained" size="medium" sx={{ borderRadius: 'var(--border-radius-accordian)' }} >JOIN</Button>} />}>
                          <ListItemText primary={<Typography variant="subtitle2">{key.title}</Typography>} secondary={<Typography variant="caption" color='var(--color-dashboard-tasklist)'>{key.time}</Typography>} />
                          {/* primaryTypographyProps={{fontSize: '14px'}} primary={<Typography variant="body2" fontWeight={500}>{key.title}</Typography>} */}
                        </ListItem>
                        <ListItem>
                          <ListItemText secondary={key.agenda} /> {/* removed primaryTypographyProps={{ fontSize: '14px' }}*/}
                        </ListItem>
                        <ListItem>
                          <AvatarGroup max={4}>
                            {key.invited.map((v, i) =>
                              <Avatar key={i} alt={v.name} src={v.src} sx={{ width:'var(--equal-hw2)', height:'var(--equal-hw2)'}} />
                            )}
                          </AvatarGroup>
                        </ListItem>
                      </List>
                    </Card>
                  )
                })}
              </Box>
            </CardContent>
          </Card>
    </>
  )
}

export default UpcomingEvent