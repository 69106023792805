/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React from 'react';
import InputTextField from './component/inputTextField';
import InputSingleSelect from './component/InputSingleSelect';
import InputDatePicker from './component/InputDatePicker';
import { subscribe } from './helper/publisher.js';

const RenderHelper = ({model, id, readOnly, disabled}) => {

  const [valueUpdated, setValueUpdated] = React.useState(true);
  const [modelRender, setModelRender] = React.useState(model);

  function subscriberFunction(data) {
    setValueUpdated(false);
  }

  React.useState(() => {
    subscribe(subscriberFunction, id);
  }, [])

  React.useEffect(() => {
    if(!valueUpdated){
      setValueUpdated(true);
    }
  }, [valueUpdated])

  React.useEffect(() => {
    setModelRender(model);
     
  }, [readOnly,disabled]);
  
  React.useEffect(() => {
    setValueUpdated(false);
  }, [modelRender])

  return (
    valueUpdated ? 
      <>
          {modelRender?.map((v,i) => {
              switch(v.type){
                  case 'textField': return (<InputTextField model={model} modelKey={i} key={i} id={id}  {...v} />);
                  case 'singleSelect': return (<InputSingleSelect model={model} modelKey={i} id={id} key={i} {...v} />);
                  case 'dateField': return (<InputDatePicker model={model} modelKey={i} id={id} key={i} {...v} />);
              }
          })}
      </>
      :
      <></>
  )
}
export default RenderHelper;