import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import DeleteIcon from '@mui/icons-material/Delete';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const MedicalDetails = () => {

  let medicalInsuranceModel = [
    { label: 'Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z\s]+$/, errorMessage:'Field contains invalid character(s)' }]},
    { label: 'Gender', type: "singleSelect", value: '', validators: [{name:'required'}] , data: [{ value: '', label: '' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }]},
    { label: 'Date Of Birth', type: "dateField", validators: [{name:'required'}] , value: '' },
    { label: 'Dependent (Yes/No)', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }] },
    { label: 'Relation', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
    { label: 'Health Conditions (Optional)', validators: [{name:''}] , type: "textField", size: 'small', value: '' },
    { label: 'Address', type: "textField", validators: [{name:'required'}] , size: 'small', value: '', variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
    { label: 'Country', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'bharat', label: 'Bharat' }, { value: 'china', label: 'China' }, { value: 'america', label: 'America' }, { value: 'russia', label: 'Russia' }] },
    { label: 'State', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'punjab', label: 'Punjab' }, { value: 'kerala', label: 'Kerala' }] },
    { label: 'City', type: "singleSelect", validators: [{name:'required'}] , value: '', data: [{ value: 'delhi', label: 'Delhi' }, { value: 'gaziyabad', label: 'Ghaziabad' }, { value: 'noida', label: 'Noida' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
    { label: 'Pincode', type: "textField", validators: [{name:'required'}] , size: 'small', value: '' }
  ];

  let medicalInsuranceHelper = new FormHelper({ model: medicalInsuranceModel, id: 'medicalInsurance' });

     const [dateOfBirth, setDateOfBirth] = React.useState(dayjs('2022-04-07'));
     const [dateOfBirthDept, setDateOfBirthDept] = React.useState(dayjs('2022-04-07'));
     const [dateOfBirthEPF, setDateOfBirthEPF] = React.useState(dayjs('2022-04-07'));
     const [medicalInsurances, setMedicalInsurances] = React.useState([new FormHelper({ model: medicalInsuranceModel, id: 'medicalInsurance0' })]);
     const [isTouched, setIsTouched] = React.useState(false);
     const [isFilled, setIsFilled] = React.useState(false);

     medicalInsuranceHelper.isTouched.touched.subscribe(() => {
   setIsTouched(true);
 })

 medicalInsuranceHelper.isFilled.filled.subscribe(() => {
     setIsFilled(medicalInsuranceHelper.isFilled.filled.get())
   })

     const addNewMedicalInsurances = () => {
          setMedicalInsurances([...medicalInsurances, new FormHelper({ model: medicalInsuranceModel, id: 'medicalInsurance0' + medicalInsurances.length }) ]);
          setIsFilled(false)
        }

     const removeInsurance = (i) => {
     let insuranceMembers = [...medicalInsurances];
     insuranceMembers.splice(i, 1);
     if (!insuranceMembers.length)
          insuranceMembers.push(new FormHelper({ model: medicalInsuranceModel, id: 'medicalInsurance0' }));
     setMedicalInsurances(insuranceMembers);
     setIsFilled(true)
     }

     return(

          <>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
               {medicalInsurances.map((v, i) => {
                 return <Box key={'medicalInsurances' + i} component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                   <Box sx={{ gridColumn: 'span 3' }} id={v + "jds"}>
                   <Grid container spacing={2} display='flex'>
                         <Grid sx={{display:'flex', alignContent:'center', flexWrap:'wrap'}} item xs={10}>
                         <Typography variant='h8' fontWeight={800}>Person {i+1}</Typography>
                         </Grid>
                         <Grid item xs={2} className="dashboardStyle">
                             <IconButton aria-label="Example" onClick={() => removeInsurance(i)} disabled={!isTouched}>
                                     <DeleteIcon icon={faEllipsisV}  size="small" />
                             </IconButton>
                         </Grid>
                   </Grid>
                   </Box>
                   <RenderHelper {...medicalInsuranceHelper.model}/>
                   {/* <TextField id={v["name"]} label="Name" variant="outlined" size="small" />
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["gender"]}
                       label="Gender"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
                     <DatePicker
                       label="Date Of Birth"
                       value={v[dateOfBirth]}
                       onChange={(newValue) => {
                         let insurances = medicalInsurances;
                         insurances[i][dateOfBirth] = newValue;
                         setMedicalInsurances(insurances);
                       }}
                       renderInput={(params) => <TextField {...params} size="small" />}
                     />
                   </LocalizationProvider>
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">Dependent (Yes/No)</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["dependent"]}
                       label="Dependent (Yes/No)"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">Relationship</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["relationship"]}
                       label="Relationship"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <TextField id={v['healthConditions']} label="Health Conditions" variant="outlined" helperText="optional" size="small" />
                   <TextField sx={{ gridColumn: 'span 2' }} multiline id={v['address']} label="Address" variant="outlined" size="small" />
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">City</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["city"]}
                       label="City"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">State</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["state"]}
                       label="State"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <FormControl size="small">
                     <InputLabel id="demo-simple-select-label">Country</InputLabel>
                     <Select
                       labelId="demo-simple-select-label"
                       id="demo-simple-select"
                       // value={v["country"]}
                       label="Country"
                     // onChange={handlePassingYear}
                     >
                       <MenuItem value={"y"}>Yes</MenuItem>
                       <MenuItem value={"n"}>No</MenuItem>
                     </Select>
                   </FormControl>
                   <TextField id={v['pincode']} label="Pincode" variant="outlined" size="small" /> */}
                 </Box>
               })}
               <Box sx={{ border: "var(--border-add-btn)", borderRadius: "var(--border-radius-add-btn)", display: "flex", justifyContent: "center" }}>
                 <Button sx={{ minWidth: "var(--min-width-15)" }} startIcon={<AddIcon />} onClick={addNewMedicalInsurances} disabled={!isFilled}>
                   Add Person
                 </Button>
               </Box>
             </Box>

          </>

     );
}

export default MedicalDetails