import React from 'react'
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import image2 from '../assets/images/welcomePage.png';
import image4 from '../assets/images/image4.png';
import Footer from '../layouts/footer/Footer';
import { useNavigate } from 'react-router-dom'



const Img = styled('img')({
  margin: 'auto',
});

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  // const ref = React.useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="bgImage">
      <div className="flex-center">

        <Container fluid='true' sx={{ maxHeight: '550px', px: { xs: 1, sm: 6 } }}>
          <Card sx={{ width: '100%', alignItems: 'center', display: 'flex' }}>
            <div className="image">
              <img alt="complex" src={image2} sx={{maxHeight: '500px' }} />
            </div>
            <div className='center'>
              <div className='padding'>
                <Img alt="complex" src={image4} sx={{ height: '10vh' }} />
                <Typography variant="h5" color="text.primary" sx={{ pb: 6,pt:4 }} className="center">
                  Login to your account
                </Typography>
                {/* <div className='center'> */}
                <FormControl sx={{ m: 'auto', width: '65%' }}>
                  <TextField id="outlined-basic" label="Email" variant="outlined" />
                </FormControl>
                <FormControl sx={{ m: 2, width: '65%', mb: 1 }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Typography variant="body2" align="right" sx={{ mr: '17%', pb: 3,textDecoration:'none' }}>
                  <a href='' style={{color:'#1976d2'}}>Forgot password?</a>
                </Typography>
                <Button variant="contained" size="large" sx={{ width: '65%' }} onClick={() => navigate('welcome')}>
                  LOG IN
                  {/* LOG IN  */}
                </Button>

              </div>
            </div>

          </Card>
        </Container>

      </div>

      <Footer />
    </div>
  )
}

export default Login