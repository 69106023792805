import React, { useRef } from 'react'
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import InputBase from '@mui/material/InputBase';
import { useState } from 'react';
// import Fab from '@mui/material/Fab';
// import PauseIcon from '@mui/icons-material/Pause';
// import { PlayArrow as PlayArrowIcon } from '../utils/AllImportsHelper';
import VideoThunbnailImg from '../assets/images/videoThunbnailImg.png'
import Divider from '@mui/material/Divider';
import introVideo from '../assets/video/companyVideo.mp4';
import mandatoryData from '../datafiles/Videos/mandatoryVideo.json';
import trainingData from '../datafiles/Videos/trainingVideo.json';
import AnimatedCoin from '../assets/images/animatedCoin.gif';
import LightBulb from '../assets/images/lightbulb-vector.svg';
import InfoIcon from '../assets/images/info-vector.svg';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Fab from '@mui/material/Fab';


const Videos = () => {

  const coinsWeHave = 48;
  //search bar start/////////////
  // const Search = styled('div')(({ theme }) => ({
  //   position: 'relative',
  //   borderRadius: '10px',
  //   backgroundColor: '#FFFFFF',
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     width: 'auto',
  //   },
  // }));

  // const SearchIconWrapper = styled('div')(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   color: 'inherit',
  //   '& .MuiInputBase-input': {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create('width'),
  //     width: '100%',
  //     [theme.breakpoints.up('sm')]: {
  //       width: '12ch',
  //       '&:focus': {
  //         width: '20ch',
  //       },
  //     },
  //   },
  // }));
  //////////

  //////////////////  Accordian start ///////////

  const [expanded, setExpanded] = React.useState('panel2');
  const [selectedVideo, setSelectedVideo] = React.useState('none')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  /////////////////  Accordian End  ////////////
  //////item style //////////
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));
  /////////End///////////////////////

  ////videos panel//
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [showPlayButton, setShowPlayButton] = useState(false);
  //change it to true for the fab to show up
  const videoRef = useRef();


  // function handleMouseEnter() {
  //   setShowPlayButton(true);
  // }

  // function handleMouseLeave() {
  //   setShowPlayButton(false);
  // }

  // function handlePlayClick() {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //     setIsPlaying(false);
  //   }
  //   else {
  //     videoRef.current.play();  
  //     setIsPlaying(true);
  //   }
  // }
  ////end////




  const [currentThumb, clickedThumb] = useState(VideoThunbnailImg);
  const [currentVideo, clickedVideo] = useState(introVideo);

  function clicked(index) {
    console.log(index)
    var clickedSourceVideo = mandatoryData[index].videos;
    var clickedSourceImage = mandatoryData[index].video;
    let clr = '#A8CFFB'
    setSelectedVideo(clr)
    console.log(clickedSourceVideo)
    console.log(clickedSourceImage)
    clickedVideo(clickedSourceVideo);
    clickedThumb(clickedSourceImage);
  }

  return (
    <Box>
      <Grid container spacing={2} pb={8}>

        {/* //LEFT PART */}
        <Grid item xs={12} md={12} lg={8} sx={{ minHeight: '100vh', height: 'inherit' }}>
          <Box sx={{ height:1 }}>
            <Box sx={{ height:1 }}>
              <Stack sx={{ height: 'inherit','& .MuiPaper-elevation': { height: 'inherit' } }}>
                <Item sx={{ borderRadius: 'var(--border-radius-none)', boxShadow: 'var(--box-shadow-none)', p:'1rem' }}>
                  <Stack>
                    <Box sx={{ width: 1 }}>  {/*mui replacement for width:100%*/}
                      <Box sx={{ position: 'relative' }}>
                        <Box sx={{ minWidth: 300, flexGrow: 1 }}>
                          <video
                            controls
                            ref={videoRef}
                            src={currentVideo}
                            poster={currentThumb}
                            className="video-tag"
                          // onPlay={() => {setIsPlaying(true)}}
                          // onPause={() => {setIsPlaying(false)}}
                          />
                          {/* {showPlayButton && (
                            <Fab onClick={handlePlayClick} sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              opacity: .8,
                              transform: 'translate(-50%, -50%)'
                            }} color="primary" aria-label="play arrow">
                              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                            </Fab>
                          )} */}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{mt:2}}>
                        <Typography color="var(--color-black)" variant="h6" fontWeight="var(--font-weight-7)" gutterBottom>
                          The Onboarding Process
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Box>
                          <Typography variant='body2'>Intro</Typography>
                        </Box>
                        <Divider sx={{ mr: 'var(--margin-divider)', ml: 'var(--margin-divider)' }} orientation="vertical" flexItem />
                        <Box>
                          <Typography variant='body2'>32m 50s</Typography>
                        </Box>
                        <Divider sx={{ mr: 'var(--margin-divider)', ml: 'var(--margin-divider)' }} orientation="vertical" flexItem />
                        <Box>
                          <Typography variant='body2'>Released: May 18, 2022</Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/* backgroundColor: "#F9F9F9" */}
                    <Item sx={{ position: 'relative', top: '2rem', boxShadow: 'none',backgroundColor:'#F9F9F9' }}>
                      <Box>
                        <Typography variant="h6" color='text.primary'>Description</Typography>
                        <Typography variant="body2" color='var(--color-black)'> {/*color:'text.secondary' */}
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                          make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                          standard dummy text ever since the 1500s
                          
                        </Typography>
                      </Box>
                    </Item>
                  </Stack>
                </Item>
              </Stack>
            </Box>
            {/* <Box sx={{ backgroundColor: '#F8FBFF', minHeight: "16rem" }}></Box> */}
          </Box>
        </Grid>
        
        {/* //RIGHT PART */}
        <Grid item xs={12} md={12} lg={4} sx={{ minHeight: '100vh', height: 'inherit' }}>
          <Stack sx={{ height: '100%' }}>
            <Box backgroundColor='white' sx={{ height: 'inherit' }}>
              <Accordion sx={{ m: 0, backgroundColor: "var(--color-lightestblue)" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  sx={{ p: 'var(--padding-right-only)', backgroundColor: "var(--color-lightestblue)" }} //removed minHeight: '0px!important',
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Box>
                    <Box sx={{ p: "var(--padding-videos-infoicon)", display: 'inline-flex' }}>
                      <img src={InfoIcon} alt="React Logo" />
                    </Box>
                    <Box display="inline-flex" alignItems='center'>
                      <Typography color='var(--color-grey)'>Mandatory Videos</Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "var(--color-white)" }}>
                  {mandatoryData.map((key, index) => {
                    return (
                      <Stack direction="row" spacing={2} key={index} alignItems="center">
                        <Box>{key.number}</Box>
                        <Box sx={{ minWidth: 'calc(100% - 25px)' }} >
                          <Stack direction="row" sx={{ mb: 2 }}>
                            <Item onClick={() => clicked(index)} sx={{ ':hover': { bgcolor: 'var(--background-color-hover-videos)' }, minWidth: 'var(--min-width-full)', borderRadius: "0.75rem" }}>
                              <Stack direction="row" spacing={2} sx={{ minWidth: 'var(--min-width-full)' }}>
                                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                  <img src={key.video} alt="React Logo" className='video-thumb' />
                                </Box>

                                <Box>
                                  <Box><Typography color="text.primary" fontWeight={600} sx={{fontSize:'0.938rem'}}>{key.title}</Typography></Box>
                                  <Box>
                                      <Typography variant='caption' color="text.secondary" display='inline-flex'>Earn {key.earn}</Typography>
                                      <Box sx={{display:"inline-flex", width:"0.7rem",height:"0.7rem",scale:'2.4',marginLeft:"0.3rem"}}><img src={AnimatedCoin} alt="coin-logo"/></Box>
                                  </Box>
                                  <Box>
                                      <Typography fontSize=".813rem" color="text.secondary" display='inline-flex'>{key.time}</Typography>
                                  </Box>
                                  <Box sx={{display:"flex", alignItems:'center'}}>
                                      <VisibilityIcon sx={{height:'1rem', width:'1rem',mr:'.3rem'}}/>
                                      <Typography variant='caption' color="text.secondary" display='inline-flex'>{key.views}</Typography>
                                  </Box>
                                </Box>
                              </Stack>
                            </Item>
                          </Stack>
                        </Box>
                      </Stack>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ m: 0 }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  sx={{ p: 'var(--padding-right-only)', backgroundColor: "var(--color-lightestblue)" }} //removed minHeight: '0px!important',
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header">
                  <Box>
                    <Box sx={{ p: "var(--padding-videos-bulbicon)", display: 'inline-flex' }}>
                      <img src={LightBulb} alt="React Logo" />
                    </Box>
                    <Box display="inline-flex" alignItems='center'>
                      <Typography color='var(--color-grey)'>Training Videos</Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: "var(--color-white)" }}>
                  {trainingData.map((key, index) => {
                    return (
                      <Stack direction="row" spacing={2} key={index} alignItems="center">
                        <Box>{key.number}</Box>
                        <Box sx={{ minWidth: 'calc(100% - 25px)' }} >
                          <Stack direction="row" sx={{ mb: 2 }}>
                          {/*':hover': { bgcolor: 'var(--background-color-hover-videos)' --- bgcolor:selectedVideo } */}
                            <Item onClick={() => clicked(index)} sx={{ ':hover': { bgcolor: 'var(--background-color-hover-videos)' }, minWidth: 'var(--min-width-full)', borderRadius: "0.75rem" }}>
                              <Stack direction="row" spacing={2} sx={{ minWidth: 'var(--min-width-full)' }}>
                              <Box sx={{ display: 'inline-flex', alignItems: 'center',justifyContent:'center', position:'relative' }}>
                                  <img src={key.video} alt="React Logo" className='video-thumb' />
                                  { (coinsWeHave < key.cost) ? (<LockIcon className='overlay-whitelock-icon' />) : '' }
                              </Box>

                                <Box>
                                  <Box><Typography color="text.primary" fontWeight={600} fontSize='0.938rem'>{key.title}</Typography></Box>
                                  <Box>
                                      <Typography variant='caption' color="text.secondary" display='inline-flex'>Earn {key.earn}</Typography>
                                      <Box sx={{display:"inline-flex", width:"0.7rem",height:"0.7rem",scale:'2.4',marginLeft:"0.3rem"}}><img src={AnimatedCoin} alt="coin-logo"/></Box>
                                  </Box>
                                  <Box>
                                      <Typography fontSize=".813rem" color="text.secondary" display='inline-flex'>{key.time}</Typography>
                                  </Box>
                                  <Box sx={{display:"flex", alignItems:'center'}}>
                                      <VisibilityIcon sx={{height:'1rem', width:'1rem',mr:'.3rem'}}/>
                                      <Typography variant='caption' color="text.secondary" display='inline-flex'>{key.views}</Typography>
                                  </Box>
                                </Box>
                              </Stack>
                            </Item>
                          </Stack>
                        </Box>
                      </Stack>
                    )
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* </Item> */}
          </Stack>



        </Grid>

      </Grid>
    </Box>
  )
}

export default Videos