import React, { useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import SpinWheelFunction from './SpinWheelFunction'
import { Button, Stack, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {rewardClaimed,setWheel,rewardPrinted} from '../features/wheel/wheelSlice';


import { closeDialog } from '../features/dialogBox/dialogBoxSlice';

const SpinWheel = ({parentId}) => {
  const segments = ["20 Points", "40 Points", "60 Points", "80 Points", "100 Points"];
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#FF9000"
  ];
  // const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const count = useSelector((state) => {return (state.wheel.id == 'wheel') ? state.wheel.count : 0});
  const disableClaimReward = useSelector((state) => state.wheel.disableClaimReward);

  const showPrintValue = useSelector((state) => state.wheel.currentSegment);
  // const [showPrintValue, setShowPrintValue] = React.useState(false);
  // const onFinished = (winner) => {
  //   setShowPrintValue(winner);
  //   if (count < 3) {
  //     setCount(count + 1);
  //   }
  //   // console.log(count)
  // };

  React.useEffect(() => {
    console.log("Wheel init")
    dispatch(setWheel('wheel'));
  }, [])

  const handleDialogClose = () => {
    dispatch(closeDialog(parentId));
  };

  const claimReward = () => {
    dispatch(rewardClaimed('wheel'))
  }

  const [scroll, setScroll] = React.useState('paper');
  

  // const showPrintValue =()=>{
  //   dispatch(rewardPrinted('wheel'))
  // }

  return (
  // <Dialog
  //     open='true'
  //     // onClose={handleClose}
  //     scroll={scroll}
  //     aria-labelledby="scroll-dialog-title"
  //     aria-describedby="scroll-dialog-description"
  //   >
    <Box className="App">
        <Stack sx={{ backgroundColor: "var(--color-lightblue)", display:'absolute', top:'0' }}>
                  <Box sx={{p:'0.25rem .5rem'}}>
                      <Grid container direction="row" alignItems="center">
                          <Grid item xs={10}>
                              <Box pl='1rem' display='flex' sx={{flexDirection:'column'}}>
                                  <Typography variant="h6" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Spin Wheel</Typography>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                              <IconButton aria-label="upload picture" component="label" onClick={handleDialogClose} color='var(--color-black)'>
                                  <CloseIcon sx={{color:'var(--color-black)'}}/>
                              </IconButton>
                          </Grid>
                      </Grid>
                  </Box>
        </Stack>

      <DialogContent dividers={scroll === 'paper'} sx={{maxHeight: '40rem', overflow: 'auto'}}>
          <Box>
            <Box >
              <Typography variant='h8' fontWeight={800}>
                Count: {count}/3
              </Typography>
            </Box>
          </Box>

          <Box id="wheelCircle">
            <SpinWheelFunction
              segments={segments}
              segColors={segColors}
              winningSegment=""
              // onFinished={(winner) => onFinished(winner)}
              primaryColor="black"
              primaryColorAround="#ffffffb4"
              contrastColor="white"
              buttonText="Spin"
              isOnlyOnce={false}
              size={190}
              upDuration={60}
              downDuration={300}
            />
          </Box>

          <Box>
            {showPrintValue != false && (
              <Box sx={{ pl: 2 }}>
                <Typography variant='h8' fontWeight={800}>
                  "you have won {showPrintValue}"
                </Typography>
              </Box>)}
          </Box>

          <Box sx={{ mb: 2}}>
            <Stack direction="row" justifyContent="center" alignItems="center">
              {console.log({disableClaimReward})}
            <Button sx={{ borderRadius: '30px' }} variant="contained" onClick={claimReward} disabled={disableClaimReward}>claim reward</Button>
              {/* {showPrintValue ? <Button sx={{ borderRadius: '30px' }} variant="contained">claim reward</Button> : <Button sx={{ borderRadius: '30px' }} variant="contained" disabled>claim reward</Button>} */}
            </Stack>
          </Box>
      </DialogContent>
    </Box>
  // </Dialog>
  );
}

export default SpinWheel