import React from 'react'
// import { useRef, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
// import { styled, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px dotted ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', m:'1rem'}} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const top10Films = [
     
     { title: 'Amadeus', year: 1984 },
     { title: 'To Kill a Mockingbird', year: 1962 },
     { title: 'Toy Story 3', year: 2010 },
     { title: 'Logan', year: 2017 },
     { title: 'Full Metal Jacket', year: 1987 },
     { title: 'Dangal', year: 2016 },
     { title: 'The Sting', year: 1973 },
     { title: '2001: A Space Odyssey', year: 1968 },
     { title: "Singin' in the Rain", year: 1952 },
     { title: 'Toy Story', year: 1995 },
];


const HelpCenterDrawer = ({parentId}) => {

const [expanded, setExpanded] = React.useState('panel1');

const handleChange = (panel) => (event, newExpanded) => {
  setExpanded(newExpanded ? panel : false);
};

const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
    setOpen(true);
  };

const handleClose = () => {
    setOpen(false);
  };

// function generate(element) {
//   return [0].map((value) =>
//     React.cloneElement(element, {
//       key: value,
//     }),
//   );
// }
// const [dense, setDense] = React.useState(false);

/////////////-----upload file---////////////////
// const [image, setImage] = useState();
// const [preview, setPreview] = useState();

//create reference
// const fileInputRef = useRef();
//simulate click on this input  -attach ref to input
// to put value in state we need to put onChange

// useEffect(() => {
//   if (image) {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       setPreview(reader.result);
//     };

//     //reader.readAsArrayBuffer <-  arraybuffer
//     reader.readAsDataURL(image); //represented as a base64string
//   } else {
//     setPreview(null);
//   }
// }, [image]);

const dispatch = useDispatch();
const handleDrawerClose = () => {
  dispatch(closeDrawer(parentId));
};

//stack item//
// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));
//

return (
    <>
    <Box sx={{minWidth:{xs:'var(--width-drawer-sm)', sm:'var(--width-drawer-sm)', md:'var(--width-drawer)', lg:'var(--width-drawer)'}}}>
    <Stack sx={{ backgroundColor: "var(--color-lightblue)", mb:'var(--equal-hw1)' }}>
              <Box sx={{p:'var(--padding-top-accordian-header)'}}>
                  <Grid container direction="row" alignItems="center">
                      <Grid item xs={10}>
                          <Box pl='1rem' display='flex'>
                              <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Write us</Typography>
                          </Box>
                      </Grid>
                      <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                          <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                              <CloseIcon sx={{color:'var(--color-black)'}}/>
                          </IconButton>
                      </Grid>
                  </Grid>
              </Box>
    </Stack>
    <Box  sx={{ maxHeight:'60rem', overflow:'auto'}}>
          <Typography sx={{display:'inline-flex', p:'1rem', color:'var(--color-grey)'}}>
          From
          </Typography>
          <Autocomplete
              sx={{display:'inline-flex', width:{sm:'var(--width-autocomplete-subject)', md:'var(--width-autocomplete-to)'}}}
              multiple
              id="tags-filled"
              options={top10Films.map((option) => option.title)}
              freeSolo
              renderTags={(value, getTagProps) =>
                  value.map((option, index1) => (
                  <Chip 
                      sx={{backgroundColor:'var(--background-color-chip)'}} 
                      avatar={<Avatar><PersonIcon sx={{backgroundColor:'var(--background-color-chip)'}} /></Avatar>} 
                      label={option} {...getTagProps({ index1 })} />
                  ))
              }
              renderInput={(params) => (
                  <TextField
                  id=""
                  {...params}
                  />
              )}
          />
    </Box>
          <Divider />
          
          <Accordion  onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{p:'1rem 0rem', m:'0rem !important'}}>
            <Typography sx={{display:'inline-flex', p:'1rem', position:'relative', color:'var(--color-grey)'}}>
            To
            </Typography>
            <Autocomplete
              sx={{display:'inline-flex', width:'var(--width-autocomplete-to)'}}
              multiple
              id="tags-filled"
              options={top10Films.map((option) => option.title)}
              freeSolo
              renderTags={(value, getTagProps) =>
                    value.map((option, index2) => (
                    <Chip  sx={{backgroundColor:'var(--background-color-chip)'}} avatar={<Avatar><PersonIcon sx={{backgroundColor:'var(--background-color-chip)'}} /></Avatar>} label={option} {...getTagProps({ index2 })} />
                    ))
              }
              renderInput={(params) => (
                    <TextField
                    id=""
                    {...params}
                    />
              )}
            />
            </AccordionSummary>
            <AccordionDetails>
            <Typography sx={{display:'inline-flex', color:'var(--color-grey)'}}>
            Cc
            </Typography>
            <Autocomplete
              sx={{display:'inline-flex', width:'var(--width-autocomplete-cc)'}}
              multiple
              id="tags-filled"
              options={top10Films.map((option) => option.title)}
              freeSolo
              renderTags={(value, getTagProps) =>
                  value.map((option, index3) => (
                  <Chip sx={{backgroundColor:'var(--background-color-chip)'}} avatar={<Avatar><PersonIcon sx={{backgroundColor:'var(--background-color-chip)'}} /></Avatar>} label={option} {...getTagProps({ index3 })} />
                  ))
              }
              renderInput={(params) => (
                  <TextField
                  id=""
                  {...params}
                  />
              )}
          />
            </AccordionDetails>
            <AccordionDetails>
            <Typography sx={{display:'inline-flex', color:'var(--color-grey)'}}>
            Bcc
            </Typography>
            <Autocomplete
              sx={{display:'inline-flex', width:'var(--width-autocomplete-cc)'}}
              multiple
              id="tags-filled"
              options={top10Films.map((option) => option.title)}
              freeSolo
              renderTags={(value, getTagProps) =>
                  value.map((option, index4) => (
                  <Chip sx={{backgroundColor:'var(--background-color-chip)'}} avatar={<Avatar><PersonIcon sx={{backgroundColor:'var(--background-color-chip)'}} /></Avatar>} label={option} {...getTagProps({ index4 })} />
                  ))
              }
              renderInput={(params) => (
                  <TextField
                  id=""
                  {...params}
                  />
              )}
          />
            </AccordionDetails>
          </Accordion>
          <Divider />
          <Box p='1rem 0rem'>
          <Typography sx={{display:'inline-flex', p:'1rem', color:'var(--color-grey)' }}>
            Subject
          </Typography>
          <Autocomplete
              sx={{display:'inline-flex', width:{sm:'var(--width-autocomplete-subject-sm)', md:'var(--width-autocomplete-subject)'}}}
              multiple
              id="tags-filled"
              options={top10Films.map((option) => option.title)}
              freeSolo
              renderTags={(value, getTagProps) =>
                  value.map((option, index5) => (
                  <Chip sx={{backgroundColor:'var(--background-color-chip)'}} avatar={<Avatar><PersonIcon sx={{backgroundColor:'var(--background-color-chip)'}} /></Avatar>} label={option} {...getTagProps({ index5 })} />
                  ))
              }
              renderInput={(params) => (
                <TextField
                  id=""
                  {...params}
                  />
              )}
            />
            </Box>
          <Divider />
          <TextField
            sx={{ width:'100%', height:"25rem"}}
            id="outlined-multiline-flexible"
            placeholder="Message"
            multiline
            maxRows={15}
          />
        <Box sx={{backgroundColor:'white', width:'100%', position:'absolute', bottom:'0', mt:'4rem', height:'4rem'}}>
          <Stack sx={{ mx:'1rem', pt:'1rem'}} direction="row" spacing={1}>
            <Button variant="contained"  onClick={handleClickOpen} startIcon={<SendIcon />} size="small">SEND</Button>
            <Button variant="text" size="small" onClick={handleDrawerClose} >DISCARD</Button>
          </Stack>
        </Box>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{ minWidth:{xs:'5rem',sm:'var(--minWidth-welcome-dialog)'}}}>
                  <Typography variant='h6'>Email sent!</Typography>
            </DialogTitle>
            <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={handleClose} variant='contained' autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
        {/* <Stack direction="row" spacing={2} ml='1rem'>
          <Button variant="contained" endIcon={<SendIcon />}>
            Send
          </Button>
          <Button sx={{':hover': { backgroundColor: "#DBEAFE", } }}>
            DISCARD
          </Button>
        </Stack> */}
      </Box>
      </>
  
);
}

export default HelpCenterDrawer