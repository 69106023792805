import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Excel from '../../assets/images/excel.png';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { closeDrawer } from '../../features/drawers/drawerSlice';
import  UploadDocument  from '../../components/drawerBody/UploadDocument';

const InvestmentDrawer = ({parentId}) => {
  //stack item//
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    dispatch(closeDrawer(parentId));
  };

  //list item//
  function generate(element) {
    return [0].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
  }
  // const [dense, setDense] = React.useState(false);
  // const [secondary, setSecondary] = React.useState(false);
  //list item end//
  return (
    <Box sx={{minWidth:{sm:'var(--width-drawer-sm)', md:'var(--width-drawer)', lg:'var(--width-drawer)'}}}>
      <Stack sx={{ backgroundColor: "var(--color-lightblue)"}}>
              <Box sx={{p:'0.25rem .5rem'}}>
                  <Grid container direction="row" alignItems="center">
                      <Grid item xs={10}>
                          <Box pl='1rem' display='flex' sx={{flexDirection:'column'}}>
                              <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Investment Details</Typography>
                              <Typography variant="body2" display="block" color='var(--color-greyblue)' >Declare your investment with attachment of required documents</Typography>
                          </Box>
                      </Grid>
                      <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                          <IconButton aria-label="upload picture" component="label" onClick={handleDrawerClose} color='var(--color-black)'>
                              <CloseIcon sx={{color:'var(--color-black)'}}/>
                          </IconButton>
                      </Grid>
                  </Grid>
              </Box>
       </Stack>
      {/* <Stack sx={{ backgroundColor: "#DBEAFE" }}>
        <Box sx={{ mx: '15px', my: '15px' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <div><Typography variant="h8" color="text.primary" fontWeight="bold" display="block" gutterBottom>Investment Details</Typography></div>
              <div><Typography variant="h8" color="text.secondary" display="block" gutterBottom>Declare your investment with attachment of required documents</Typography></div>
            </Grid>
            <Grid item xs={2}>
              <IconButton sx={{ ml: "40px" }} aria-label="upload picture" component="label" onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Stack> */}
      <Box sx={{ maxHeight:'40rem', overflow:'auto', pt:'var(--equal-hw1)'}}>
          <Stack sx={{mx: '1rem', boxShadow: 'none' }} >
            <List>
              {generate(
                <Card>
                  <ListItem secondaryAction={<IconButton edge="end" aria-label="delete"><FileDownloadIcon color="primary" /></IconButton>}>
                    <ListItemAvatar sx={{height:'40px'}}><img src={Excel} alt="React Logo" /></ListItemAvatar>
                    <ListItemText primary="Investment Declaration form" secondary="4mb" />
                  </ListItem>
                </Card>
              )}
            </List>
          </Stack>
          {/* <Stack sx={{ mt: '1rem', mx: '1rem', boxShadow: 'none' }}>
            <Item sx={{ border: "2px dashed rgba(0, 0, 0, 0.12)", borderRadius: '12px', ':hover': { backgroundColor: "#DBEAFE", borderColor:'var(--color-mui-primary-blue)'} }}>
              <Box>
                <IconButton color="primary" aria-label="upload picture" component="label">
                  <UploadFileIcon />
                </IconButton>
              </Box>
              <Box><span><Button variant="text">Click to upload </Button></span> <span> or drag and drop </span></Box>
              <Box><Typography variant="h8" color="text.secondary" display="block" gutterBottom>Only XLSX, XML, XLS or word doc (max. 3MB)</Typography></Box>
            </Item>
          </Stack> */}
          <Box>
          <UploadDocument />
          </Box>
      </Box>
      <Box sx={{backgroundColor:'white', width:'100%', position:'absolute', bottom:'0', mt:'4rem', height:'4rem'}}>
        <Stack sx={{ mx:'1rem', pt:'1rem'}} direction="row" spacing={1}>
          <Button variant="contained" size="small">SUBMIT</Button>
          <Button variant="text" size="small" onClick={handleDrawerClose} >CANCEL</Button>
        </Stack>
      </Box>
    </Box>
  )
}

export default InvestmentDrawer