import validationHelper from './validationHelper';

const modelHelper = ({model,id}) => {

  let self = {};

  self.id = id;
  self.initialModel = [...model];
  self.model = [...model].map(v => {
    if(v.validators?.length){
      v.validation = validationHelper().validation({validators: v.validators, errorMessage: v?.errorMessage});
    }
    return v;
  });
    
  return self;
}

export default modelHelper;