import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
// import { useTheme } from '@mui/material/styles';


const DynamicDrawer = ({ data, PaperProps }) => {
  const [open, setOpenDrawer] = useState(data.open);
  // const theme = useTheme();

  const dynamicDrawer = () => {
    setOpenDrawer(!open);
    // data.onClick();
  };


  useEffect(() => {
    if (data.open) {
      dynamicDrawer();
    }
  }, [data]);

  let Body = data.Body;
  // let Body = data.Body ?? <></>;
  return (
    <>
      <div>
        <React.Fragment>
          <Drawer anchor="right" open={open} onClose={dynamicDrawer} PaperProps={PaperProps} style={{ zIndex: 1202 }}>
            <div>
              <Body></Body>
            </div>
          </Drawer>
        </React.Fragment>
      </div>

    </>
  );
};

export default DynamicDrawer;