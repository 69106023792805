import React from 'react';
import {
     Box, Typography, Card
   } from '../../utils/AllImportsHelper';
import Person2Icon from '@mui/icons-material/Person2';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

var tempPD = JSON.parse(localStorage.getItem("tempPD"))

const PersonalDetails = ({readOnly}) => {

    setTimeout(()=>{
        personalDetailsHelper.set({
            'First Name': tempPD[0]["First Name"],
            "Last Name": tempPD[0]["Last Name"],
            "Gender": tempPD[0]["Gender"],
            "Date Of Birth": tempPD[0]["Date Of Birth"],
            "Alternate Email": tempPD[0]["Alternate Email"],
            "Phone Number": tempPD[0]["Phone Number"],
            "Alternate Number": tempPD[0]["Alternate Number"],
            "Address": tempPD[0]["Address"],
            "City": tempPD[0]["City"],
            "State": tempPD[0]["State"],
            "ZIP Code": tempPD[0]["ZIP Code"]
        } , ()=>{
            console.log("PD : Data has been fetched from local Storage")
        })
    }, 800) 

    var dirtyValues = {}
    
    let inputProps = readOnly ? { readOnly: readOnly } : {}; 

    let personalDetailsModel = [
        {  inputProps: inputProps, label: 'First Name', type: "textField", size: 'small', value: '', validators:[{ name: 'required'},{ name:'pattern', regex:/^[A-Za-z]+$/, errorMessage:'Field must only contain alphabets' }]},
        {  inputProps: inputProps, label: 'Last Name', type: "textField", size: 'small', value: '', validators:[{ name:'pattern', regex:/^[A-Za-z]+$/, errorMessage:'Field must only contain alphabets' }]},
        {  inputProps: inputProps, label: 'Gender', type: "singleSelect", value: '', validators:[{ name: 'required'}], data: [{ value: '', label: '' }, { value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }, { value: 'preferNotToSay', label: 'Prefer not to say' }], },
        {  inputProps: inputProps, label: 'Date Of Birth', type: "dateField",value:'', validators:[{name:'required'}]},
        {  inputProps: inputProps, label: 'Alternate Email', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/, errorMessage:'e.g: hello@gmail.com' } ], variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
        {  inputProps: inputProps, label: 'Phone Number', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}] },
        {  inputProps: inputProps, label: 'Alternate Number', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}] },
        {  inputProps: inputProps, label: 'Address', type: "textField", size: 'small', value: '', variant: "outlined", validators:[ { name: 'required'}], sx: { gridColumn: 'span 2' },multiline: true },
        {  inputProps: inputProps, label: 'City', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'kingston', label: 'Kingston' },{ value: 'delhi', label: 'Delhi' }, { value: 'ghaziabad', label: 'Ghaziabad' }, { value: 'noida', label: 'NOIDA' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
        {  inputProps: inputProps, label: 'State', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'ny', label: 'New York' }, { value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'panjab', label: 'Panjab' }, { value: 'kerala', label: 'Kerala' }] },
        {  inputProps: inputProps, label: 'ZIP Code', type: "textField", size: 'small', value: '12401', validators:[ { name: 'required'}], sx: { gridColumn: 'span 2' } }
    ];

    const personalDetailsHelper = new FormHelper({ model: personalDetailsModel, id: 'personalDetails' });

    personalDetailsHelper.isActiveTouch.activeTouch.subscribe((prop)=>{

        Object.keys(personalDetailsHelper.model.model).forEach(key => {
            let current = personalDetailsHelper.model.model[key]
                if(current.label === prop.label){
                    if(current.value)
                        {
                            dirtyValues[key] = current
                        }
                }
        })

        console.log("personalDetailsHelper",personalDetailsHelper.get())
        tempPD[0] = personalDetailsHelper.get()
        localStorage.setItem("tempPD", JSON.stringify(tempPD[0]))

    }, "workDetailsComponent")
      

    return(
        <>
            <Card className='personal-details' sx={{ width:'100%', borderRadius: "var(--border-radius-accordian)", mb:'1rem', pb:'1rem'}}>
                    <List>
                        <ListItem>
                            <ListItemIcon sx={{minWidth:'var(--min-width-icon-profile)'}}>
                                <Person2Icon sx={{color:'var(--color-black)'}}/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant='body1' fontWeight='var(--font-weight-7)'>Personal Details</Typography>} />  
                        </ListItem>
                    </List> 

                    <Box component="form" sx={{ p:'var(--padding-horizontal-1)', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper readOnly={readOnly} {...personalDetailsHelper.model} />
                    </Box>
            </Card>
        </>

    );
}

export default PersonalDetails