import React, { useState } from 'react';
import { Box, Typography, Card } from '../../utils/AllImportsHelper';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import WorkIcon from '@mui/icons-material/Work';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

var tempWD = JSON.parse(localStorage.getItem("tempWD"))

const WorkDetails = ({readOnly}) => {

    setTimeout(()=>{
        workDetailsHelper.set({
            'Department': tempWD[0]["Department"],
            "Reporting Manager": tempWD[0]["Reporting Manager"],
            "Date of Joining": tempWD[0]["Date of Joining"],
            "Employment Type": tempWD[0]["Employment Type"],
            "Experience": tempWD[0]["Experience"],
            "Address": tempWD[0]["Address"],
            "City": tempWD[0]["City"],
            "State": tempWD[0]["State"],
            "ZIP Code": tempWD[0]["ZIP Code"]
        } , ()=>{
            console.log("WD : Data has been fetched from local Storage")
        })
    }, 800)

    // const [readOnlyState, setReadOnlyState] = useState(true)
    var dirtyValues = {}

    let inputProps = readOnly ? { readOnly: readOnly } : {};

    let workDetailsModel = [
        { inputProps: inputProps, label: 'Employee ID', type: "textField", size: 'small', validators:[ { name: 'required'} ], disabled:true , value: '' },
        { inputProps: inputProps, label: 'Designation', type: "textField", size: 'small', validators:[ { name: 'required'} ], disabled:true ,value: '' },
        { inputProps: inputProps, label: 'Department', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: '', label: '' }, { value: 'hr', label: 'Human Resource' }, { value: 'dev', label: 'Development' }, { value: 'admin', label: 'Administration' }, { value: 'design', label: 'Design' }], validators: ['required'] },
        { inputProps: inputProps, label: 'Reporting Manager', type: "textField", size: 'small', validators:[ { name: 'required'} ], value: '' },
        { inputProps: inputProps, label: 'Date of Joining', validators:[ { name: 'required'} ], type: "dateField" },
        { inputProps: inputProps, label: 'Employment Type', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'fulltime', label: 'Full-Time' }, { value: 'parttime', label: 'Part-Time' }, { value: 'internship', label: 'Internship' }, { value: 'wfh', label: 'Work From Home' }], validators: ['required'] },
        { inputProps: inputProps, label: 'Experience', type: "textField", size: 'small', validators:[ { name: 'required'} ], value: '', variant: "outlined", sx: { gridColumn: 'span 2' }, multiline: true },
        { inputProps: inputProps, label: 'Address', type: "textField", size: 'small', validators:[ { name: 'required'} ], value: '', variant: "outlined", sx: { gridColumn: 'span 2' }, multiline: true },
        { inputProps: inputProps, label: 'City', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'kingston', label: 'Kingston' }, { value: 'delhi', label: 'Delhi' }, { value: 'ghaziabad', label: 'Ghaziabad' }, { value: 'noida', label: 'NOIDA' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
        { inputProps: inputProps, label: 'State', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'ny', label: 'New York' }, { value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'panjab', label: 'Panjab' }, { value: 'kerala', label: 'Kerala' }] },
        { inputProps: inputProps, label: 'ZIP Code', type: "textField", size: 'small', validators:[ { name: 'required'} ], value: '', sx: { gridColumn: 'span 2' } }
    ];

    const workDetailsHelper = new FormHelper({ model: workDetailsModel, id: 'workDetails' });

    workDetailsHelper.isActiveTouch.activeTouch.subscribe((prop)=>{

        Object.keys(workDetailsHelper.model.model).forEach(key => {
            let current = workDetailsHelper.model.model[key]
                if(current.label === prop.label){
                    if(current.value)
                        {
                            dirtyValues[key] = current
                        }
                }
        })

        // console.log("workDetailsHelper",workDetailsHelper.get())
        tempWD[0] = workDetailsHelper.get()
        localStorage.setItem("tempWD", JSON.stringify(tempWD[0]))
    }, "workDetailsComponent")


    return (
        <>
            <Card className='work-details' sx={{ width: '100%', borderRadius: "var(--border-radius-accordian)", mb: '1rem', pb: '1rem' }}>
                <List>
                    <ListItem>
                        <ListItemIcon sx={{ minWidth: 'var(--min-width-icon-profile)' }}>
                            <WorkIcon sx={{color:'var(--color-black)'}} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant='body1' fontWeight='var(--font-weight-7)'>Work Details</Typography>} />
                    </ListItem>
                </List>

                <Box component="form" sx={{ p: 'var(--padding-horizontal-1)', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                    <RenderHelper readOnly={readOnly} {...workDetailsHelper.model} />
                </Box>
            </Card>
        </>

    );
}

export default WorkDetails