import modelHelper from './helper/modelHelper';
import setterHelper from './helper/setterHelper';
import getterHelper from './helper/getterHelper';
import modelUpdatedHelper from './helper/modelUpdatedHelper';
import touchedHelper from './helper/touchedHelper';
import filledHelper from './helper/filledHelper';
import activeTouchHelper from './helper/activeTouchHelper';
// import {publish} from 'helper/publisher.js'

function FormHelper({model, id}) {

  let self = {};
  self.model = modelHelper({model,id});
  self.get = getterHelper(self.model).get;
  self.set = setterHelper(self.model).set;
  self.update = modelUpdatedHelper(self.model).update;
  
  //Runs when the subscribed entity is touched. It only runs twice and doesn't run after that
  self.isTouched = touchedHelper(self.model);

  // Runs only when the subscribed model is completely filled and is without any errors
  self.isFilled = filledHelper(self.model);

  //Runs everytime the subscribed entity is touched
  self.isActiveTouch = activeTouchHelper(self.model);

  //These initiation functions are responsible for subscribing the observable functions
  self.isTouched.initiation();
  self.isFilled.initiation();
  self.isActiveTouch.initiation();

  return self;
}

export default FormHelper;