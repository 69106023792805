import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';
// import Box from '@mui/material/Box';

const InputTextField = ({value, label, model, modelKey, validation, id, type, sx = {} ,change = () => {} , ...props}) => { 

  const [textValue, setTextValue] = useState(value);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  //This function enables the functionality of showing the error once the textField is clicked by checking if the field the empty and if the field contains "required" as validator or not
  const onClickTextValue = () => {
    if((textValue === "") && (model[modelKey].validators[0].name === "required")){
      setError(true)
      setErrorMessage("Field is required")
    }
  }

  const onChangeTextValue = (e) => {
    setTextValue(e.target.value);
    model[modelKey].value = e.target.value;
    var error = false;
    var errorMessage = false;

    for( let i = 0; i < validation?.length; i++){
      var res = validation[i](e.target.value);
      if(res.error){
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }

    setError(error);
    setErrorMessage(errorMessage);
    change(e); 

    /*PUBLISHING DATA TO THE TOUCHED HELPER */
    publish({data: model, id:'touched' + id});
    
    /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
    publish({data: model[modelKey], id:'activeTouch' + id});

    /*PUBLISHING DATA TO THE FILLED HELPER */
    publish({data: error, id:'filled' + id});
  }
  
  return (
   
      <TextField 
        value={textValue} 
        label={label} 
        onClick={onClickTextValue}
        onChange={onChangeTextValue} 
        error={error} 
        helperText={errorMessage ? errorMessage : ''} 
        {...props}
        sx={sx}>
      </TextField>

  )
}

export default InputTextField