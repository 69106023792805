import { authentication } from '.././config/AppConstants';
import { user } from '.././config/AppConstants';



export function getSelfToken() {
    // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMjIyZTI1NTdlZmFkNmZkMzRhOWU5ODNhMmQ1Yzg2MmI2NmJhM2Q1Mjc0MjU0MzM3YTFmN2NjYTQ0NmQwNTlhY2E4ZDM0MWI2NjYxYWQ4NDUiLCJpYXQiOjE2Nzg4NjUzOTkuNjM1ODIyLCJuYmYiOjE2Nzg4NjUzOTkuNjM1ODI3LCJleHAiOjE3MTA0ODc3OTkuNjMwMTQ4LCJzdWIiOiIzNSIsInNjb3BlcyI6W119.pywMmZmeRM7UPfVtbGYm9--R6wl4r6pxacHXgcrb1hJUzVpk-7oHchjLYRSA5CA6DYcWntJvkq977B5qY2jLQc3rE-h3uTij1ipEvrzhIFv9jmXfseKBHD07CSe3NKI7VBdd-WsHJ2JG0VGXYBYSEs9xSh8Dh_hnw0Z9ojF4XrpuhPgRTZyDnbb-EYHLyFSNR1m3tlfqVTzlEkjbje_qz35ROac4dqADcW-MLmjjEWbcZlSZ0ToQVHVJvr7iaFp27ZVBpwxJhx91zDSn3kgtJBCagtb89e8Penq2UWZ4_YY4x80HGMTtTvi1CowZzkBGSYtzKo26LIQIcR6VOizlxntxcu2UmT693MWnqBBoqT1ME_GJmrrjsqfVJywVeoFdNWsPITroe5Chkek-QVxj3h4TeoCnAKzmiOVFVX8YJswKE0-hD_6JevgJMKhO5SV_nHqEtjYwL0O9c9CVBxCMOa_V7HF3ACHN8JE8aKu6odkmAXcVni0R63bzpLhXQahdzvDh_dT5QXzTz3-Ivq5H7Ajf11zCEKd4jqFtGw7rvk5bDR6VEL-kT4Tmlm1cXIpIMlbjuzixWtLZxGfmyeVrarNCbbcT_qsdeEtkF7530YYc386UkstkclleRDfZij5jqPooAl-2-pQ9SyMEvPznAsJC2dl2JG2ulyYhYvYDBPQ";
    var obj;
    var key = user.selfToken;
    obj = (localStorage.getItem(user.user));
    if (obj != null) {
        obj = JSON.parse(obj);
        return obj[key];
    } else {
        return obj;
    }
}

export function setSelfToken(token) {
    var obj = localStorage.getItem(user.user);
    if (obj) {
        obj = JSON.parse(obj);
    } else {
        obj = {};
    }

    // var obj={};
    var key = user.selfToken;
    obj[key] = token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

export function getSsoToken() {
    var obj;
    var key = user.ssoToken;
    obj = (localStorage.getItem(user.user));
    obj = JSON.parse(obj);
    return obj[key];
}

export function setSsoToken(token) {
    var obj = (localStorage.getItem(user.user));
    obj = JSON.parse(obj);
    var key = user.ssoToken;
    obj[key] = token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

export function getAppToken() {
    // return "1234560"
    var obj;
    var key = user.appToken;
    obj = (localStorage.getItem(user.user));
    obj = JSON.parse(obj);
    return obj[key];
}
export function setAppToken(token) {
    var obj = localStorage.getItem(user.user);
    if (obj) {
        obj = JSON.parse(obj);
    } else {
        obj = {};
    }
    var key = user.appToken;
    obj[key] = token;
    localStorage.setItem(user.user, JSON.stringify(obj));

}

export function setUserAuthType(token) {
    var obj = localStorage.getItem(user.user);
    if (obj) {
        // obj=(localStorage.getItem(user.user));
        obj = JSON.parse(obj);
    } else {
        obj = {};
    }


    var key = user.authType;
    obj[key] = token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

export function getUserAuthType() {
    var obj;
    var key = user.authType;
    obj = (localStorage.getItem(user.user));
    obj = JSON.parse(obj);
    if (obj)
        return obj[key];
    else
        return false;
}

export function isSsoLogin() {
    if (getSelfToken() && getSsoToken() && getAppToken)
        return true;
    else
        return false;
}

export function isSelfLogin() {
    if (getSelfToken() && getAppToken())
        return true;
    else
        return false;
}

export function isLogin() {
    switch (getUserAuthType()) {
        case authentication.ssoAuthType:
            return isSsoLogin();
        case authentication.selfAuthType:
            return isSelfLogin();
        default:
            return false;
    }
}

export function removeUserToken() {
    console.log("remove auth called");
    localStorage.removeItem(user.user);
}



export function checkCallbackAuthType(authType) {
    console.log("remove auth called");
    if (authType == authentication.ssoAuthType) {
        return authentication.ssoAuthType;
    } else {
        return authentication.selfAuthType;
    }
}


export function setTokenForSelfAuth(token, appToken, authType) {
    setUserAuthType(authType)
    setSelfToken(token)
    setAppToken(appToken)
}


export function checkCallback(token, appToken, authType, callback, ssoToken) {
    if (checkCallbackAuthType(authType) == authentication.ssoAuthType) {
        if (token && appToken && authType && ssoToken) {
            setTokenForSelfAuth(token, appToken, authType)
            setSsoToken(ssoToken);
            window.location.assign(callback);
            return true;
        } else {
            return isLogin()
        }
    } else {
        if (token && appToken && authType) {
            setTokenForSelfAuth(token, appToken, authType)
            window.location.assign(callback);
            return true;
        } else {
            // "checkCallback else block")
            // isLogin(),"is login called in check")
            return isLogin()
        }
    }
}




