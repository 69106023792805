import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import {Button} from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const EpfDetails = () => {

    let epfDetailsModel = [
        { label: 'Previous UAN/PF Number (If available)', type: "textField", size: 'small', value: '' },
        { label: 'Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: `Father's Name`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: 'Date Of Birth', type: "dateField", value: '', validators:[{ name:'required'}] },
        { label: 'Date Of Joining', type: "dateField", value: '', validators:[{ name:'required'}] },
        { label: `Mobile Number`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}] },
        { label: `ID Card`, type: "textField", size: 'small', value: '', validators:[{ name:'required'}] },
        { label: `Name of Nominee`, type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        { label: `Designation (Optional)`, type: "textField", size: 'small', value: '' },
      ];
    
    let epfDetailsHelper = new FormHelper({ model: epfDetailsModel, id: 'epfDetails' });

    return ( 
    <> 
    <Box
        component="form"
        sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: "var(--width-full)",
        rowGap: "var(--row-gap)",
        columnGap: "var(--column-gap)"
    }}>
        <RenderHelper {...epfDetailsHelper.model} />
        {/* <TextField
            id={"previousUANNumber"}
            label="Previous UAN/PF Number (If available)"
            variant="outlined"
            size="small"/>
        <TextField id={"name"} label="Name" variant="outlined" size="small"/>
        <TextField
            id={"fathersName"}
            label="Father's Name"
            variant="outlined"
            size="small"/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Date Of Birth"
                value={dateOfBirthEPF}
                onChange={(newValue) => {
                setDateOfBirthEPF(newValue);
            }}
                renderInput={(params) => <TextField {...params} size="small"/>}/>
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Date Of Joining"
                value={dateOfJoiningEPF}
                onChange={(newValue) => {
                setDateOfJoiningEPF(newValue);
            }}
                renderInput={(params) => <TextField {...params} size="small"/>}
                helperText="optional"/>
        </LocalizationProvider>
        <TextField
            id={"mobileNumber"}
            label="Mobile Number"
            variant="outlined"
            size="small"/>
        <TextField id={"idCard"} label="ID Card" variant="outlined" size="small"/>
        <TextField
            id={"nameOfNominee"}
            label="Name Of Nominee"
            variant="outlined"
            size="small"/>
        <TextField
            id={"designation"}
            label="Designation"
            variant="outlined"
            helperText="optional"
            size="small"/> */}
    </Box>

     </>
      );
   }

   export default EpfDetails