import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { publish } from '../helper/publisher.js';

const InputDatePicker = ({label,model,modelKey,validation,id,type, change = () => {}, value, ...props}) => {
    // const today = new Date();
    // const defaultDate = today.getFullYear() + '/' + String(today.getMonth()).padStart(2, '0') + '/' + String(today.getDate()).padStart(2, '0');
    const [dateValue, setDateValue] = React.useState("");
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    
    const onClickTextValue = () => {
      if((dateValue === "") && (model[modelKey].validators[0].name === "required")){
        setError(true)
        setErrorMessage("Field is required")
      }
    }

    const onChangeDateValue = (e) => {
      model[modelKey].value = e;
      setDateValue(e);
      var error = false;
      let errorMessage = false;
      for( let i = 0 ; i<validation?.length ; i++){
        let res = validation[i](e);
        if(res.error){
          error = res.error;
          errorMessage = res.errorMessage;
          break;
        }
      }
      setError(error);
      setErrorMessage(errorMessage);
      change(e);

      /*PUBLISHING DATA TO THE TOUCHED HELPER */
      publish({data: model, id:'touched' + id});
      
      /*PUBLISHING DATA TO THE ACTIVETOUCH HELPER */
      publish({data: model[modelKey], id:'activeTouch' + id});

      /*PUBLISHING DATA TO THE FILLED HELPER */
      publish({data: error, id:'filled' + id});
    }

  return (
        <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                        <DatePicker
                            label={label}
                            value={dateValue}
                            
                            {...props}
                            onChange={onChangeDateValue}
                            renderInput={(params) => <TextField onClick={onClickTextValue}  {...params} error={error} helperText={errorMessage ? errorMessage : ''} size="small" />}
                        />
        </LocalizationProvider>
  )
}

export default InputDatePicker