import axios from 'axios';
import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = 'https://gatewayapi.eela.tech/api/v1';

class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL
    });

    // Define your endpoints here
    this.endpoints = {
      task: "task",
      upcomingEvents: "upcoming-events",
      usersHierarchy: "users/hierarchy",
      department: "department",
      users: "users",
      userById: "user",
      policyDocuments: "policy-documents",
      videoUpload: "video-upload",
      videoUploadService: "video-upload-service",
      streamCategory: "stream-category",
       // ------- Org Chart -------//
     orgUsers: 'users/hierarchy',
     orgDepartment: 'department',
     orgDepartmentUser: 'users',
     user: 'user',
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    console.debug("get:endpointKey", params)
    console.debug("get", endpointKey)

    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
    
      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data.app = getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + '/' + searchParam;
      }
      data['app'] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: 'Bearer ' + getSelfToken() }
      });
      return response;
    } catch (error) {
      throw new Error('API request failed:', { error });
    }
  }
}



export default new APIService(BASE_URL);
