
const getterHelper = (model) => {

  let self = {};

  self.model = {};

  self.get = () => {
    self.model = {};
    
    model.model.map((m) => {
        self.model[m.label] = m.value;
    })
    return self.model;
  };
    
  return self;
}

export default getterHelper;