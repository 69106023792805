import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { Button } from '../../utils/AllImportsHelper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';

const FinancialDetails = () => {

  let financialDetailsModel = [
    { label: `Account Holder's Name`, type: "textField", size: 'small', validators:[ { name: 'required'},{ name:'pattern', regex:/^[A-Za-z\s]+$/, errorMessage:'Field contains invalid character(s)' }], value: '' },
    { label: `Account Number`, type: "textField", size: 'small',validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'}], value: '' },
    { label: 'Account Type', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'savings', label: 'Savings' }, { value: 'current', label: 'Current' }] },
    { label: 'Name of the Bank', type: "singleSelect", validators:[ { name: 'required'} ], value: '', data: [{ value: 'axis', label: 'Axis' }, { value: 'HDFC', label: 'HDFC' }, { value: 'sbi', label: 'SBI' }, { value: 'ubi', label: 'UBI' }, { value: 'kotak', label: 'Kotak Mahindra Bank' }, { value: 'icici', label: 'ICICI' }] },
    { label: 'Branch', type: "textField", size: 'small', validators:[ { name: 'required'} ], value: '' },
    { label: 'Aadhaar Number', type: "textField", validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'}, {name:'length', absolute:12} ], size: 'small', value: '' },
    { label: 'PAN', type: "textField", size: 'small', validators:[ { name: 'pattern', regex:/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, errorMessage:'e.g: ABCDE1234F'} ], value: '' },
    { label: 'IFSC Number', type: "textField", size: 'small', value: '' },
    { label: 'CIF Number (Optional)', type: "textField", size: 'small', value: '' },
    { label: 'Mobile Number', type: "textField", validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain numbers'}, {name:'length', max:12} ], size: 'small', value: '' },
    { label: 'Email ID', type: "textField", validators:[ { name: 'required'},{ name:'pattern', regex:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/, errorMessage:'e.g: hello@gmail.com' } ], size: 'small', value: '' },
    { label: 'Location', type: "singleSelect", value: '', validators:[ { name: 'required'} ], data: [{ value: 'delhi', label: 'New Delhi' }, { value: 'mumbai', label: 'Mumbai' }, { value: 'bangalore', label: 'Bangalore' }, { value: 'chennai', label: 'Chennai' }, { value: 'kolkata', label: 'Kolkata' }] },
  ];

  let financialDetailsHelper = new FormHelper({ model: financialDetailsModel, id: 'financialDetails' });
  
return (
        <>
           <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
           <RenderHelper {...financialDetailsHelper.model} />
                  {/* <TextField id={"accountHolderName"} label="Account Holder's Name" variant="outlined" size="small" />
                  <TextField id={"accountNumber"} label="Account Number" variant="outlined" size="small" />
                  <FormControl size="small">
                    <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Account Type"
                    // onChange={handlePassingYear}
                    >
                      <MenuItem value={"y"}>Yes</MenuItem>
                      <MenuItem value={"n"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl size="small">
                    <InputLabel id="demo-simple-select-label">Name of the Bank</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={nameOfTheBank}
                      label="Name of the Bank"
                    // onChange={handlePassingYear}
                    >
                      <MenuItem value={"y"}>Yes</MenuItem>
                      <MenuItem value={"n"}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField id={"branch"} label="Branch" variant="outlined" size="small" />
                  <TextField id={"adhaarNumber"} label="Adhaar Number" variant="outlined" size="small" />
                  <TextField id={"panCardNumber"} label="Pan Card Number" variant="outlined" size="small" />
                  <TextField id={"ifscNumber"} label="IFSC Number" variant="outlined" size="small" />
                  <TextField id={"cifNumber"} label="CIF Number" variant="outlined" helperText="optional" size="small" />
                  <TextField id={"mobileNumber"} label="Mobile Number" variant="outlined" size="small" />
                  <TextField id={"emailId"} label="Email ID" variant="outlined" size="small" />
                  <FormControl size="small">
                    <InputLabel id="demo-simple-select-label">Location</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={location}
                      label="Location"
                    // onChange={handlePassingYear}
                    >
                      <MenuItem value={"y"}>Yes</MenuItem>
                      <MenuItem value={"n"}>No</MenuItem>
                    </Select>
                  </FormControl> */}
                </Box>
        </>
      );
   }



   export default FinancialDetails