import React from 'react';
import { Box, Typography, Card } from '../../utils/AllImportsHelper';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Emergency from '../../assets/images/emergency-vector.svg';
import FormHelper from '../../form/FormHelper';
import RenderHelper from '../../form/ReactiveForm';
import { subscribe } from '../../form/helper/publisher.js';

var tempED = JSON.parse(localStorage.getItem("tempED"))

const EmergencyDetails = ({readOnly}) => {

    setTimeout(()=>{
        emergencyProfileDetailsHelper.set({
            'Contact Person Name': tempED[0]["Contact Person Name"],
            "Relation": tempED[0]["Relation"],
            "Phone Number": tempED[0]["Phone Number"],
            "Alternate Mobile Number": tempED[0]["Alternate Mobile Number"],
            "Experience": tempED[0]["Experience"],
            "Address": tempED[0]["Address"],
            "City": tempED[0]["City"],
            "State": tempED[0]["State"],
            "ZIP Code": tempED[0]["ZIP Code"]
        } , ()=>{
            console.log("ED : Data has been fetched from local Storage")
        })
    }, 800)  

    // const [readOnlyState, setReadOnlyState] = useState(true)
    var dirtyValues = {}
    var newValues = {}

    let inputProps = readOnly ? { readOnly: readOnly } : {}; 

    let emergencyProfileDetailsModel = [
        {  inputProps: inputProps, label: 'Contact Person Name', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}, {name: 'pattern', regex:/^[A-Za-z\s]+$/ ,errorMessage:'Field contains invalid character(s)'} ] },
        {  inputProps: inputProps, label: 'Relation', type: "singleSelect", value: '', validators: [{name:'required'}], data: [{ value: '', label: '' }, { value: 'father', label: 'Father' }, { value: 'mother', label: 'Mother' }, { value: 'sibling', label: 'Sibling' }, { value: 'spouse', label: 'Spouse' }, { value: 'son', label: 'Son' }, { value: 'daughter', label: 'Daughter' }] },
        {  inputProps: inputProps, label: 'Phone Number', type: "textField", size: 'small', value: '', validators:[ { name: 'required'},{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}]},
        {  inputProps: inputProps, label: 'Alternate Mobile Number', type: "textField", size: 'small', value: '', validators:[{ name:'pattern', regex:/^[0-9]+$/, errorMessage:'Field must only contain number'},{name:'length', max:15}] },
        {  inputProps: inputProps, label: 'Address', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}], variant:'', variant: "outlined", sx: { gridColumn: 'span 2' },multiline: true },
        {  inputProps: inputProps, label: 'State', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'ny', label: 'New York' }, { value: 'up', label: 'Uttar Pradesh' }, { value: 'delhi', label: 'Delhi' }, { value: 'panjab', label: 'Panjab' }, { value: 'kerala', label: 'Kerala' }] },
        {  inputProps: inputProps, label: 'City', type: "singleSelect", value: '', validators:[ { name: 'required'}], data: [{ value: 'kingston', label: 'Kingston' },{ value: 'delhi', label: 'Delhi' }, { value: 'ghaziabad', label: 'Ghaziabad' }, { value: 'noida', label: 'NOIDA' }, { value: 'faridabad', label: 'Faridabad' }, { value: 'gurugram', label: 'Gurugram' }] },
        {  inputProps: inputProps, label: 'ZIP Code', type: "textField", size: 'small', value: '', validators:[ { name: 'required'}], sx: { gridColumn: 'span 2' } }
    ];

    const emergencyProfileDetailsHelper = new FormHelper({ model: emergencyProfileDetailsModel, id: 'emergencyProfileDetails' });

    emergencyProfileDetailsHelper.isActiveTouch.activeTouch.subscribe((prop)=>{

        Object.keys(emergencyProfileDetailsHelper.model.model).forEach(key => {
            let current = emergencyProfileDetailsHelper.model.model[key]
                if(current.label == prop.label){
                    if(current.value)
                        {
                            dirtyValues[key] = current
                        }
                }
        })

        console.log("emergencyProfileDetailsHelper",emergencyProfileDetailsHelper.get())
        tempED[0] = emergencyProfileDetailsHelper.get()
        localStorage.setItem("tempED", JSON.stringify(tempED[0]))

    }, "workDetailsComponent")

    return(
        <>
            <Card className='emergency-details' sx={{ width:'100%', borderRadius: "var(--border-radius-accordian)", mb:'1rem', pb:'1rem'}}>
                                <List>
                                        <ListItem>
                                            <ListItemIcon sx={{minWidth:'var(--min-width-icon-profile)'}}>
                                            <img className='emergency-icon-profile' src={Emergency} alt="certificate-logo"/>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography variant='body1' fontWeight='var(--font-weight-7)'>Emergency Details</Typography>} />  
                                        </ListItem>
                                </List> 
                                
                                <Box component="form" sx={{ p:'var(--padding-horizontal-1)', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', width: "var(--width-full)", rowGap: "var(--row-gap)", columnGap: "var(--column-gap)" }}>
                                    <RenderHelper readOnly={readOnly} {...emergencyProfileDetailsHelper.model} />
                                </Box>
            </Card>
        </>

    );
}

export default EmergencyDetails