import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SpinningWheel from '../assets/images/SpinningWheel.png';
import LeaderboardTab from '../components/leaderboardComponent/LeaderboardTab';
import Coin from '../assets/images/gold-coin.png';
import Badge from '../assets/images/badge.png';
import BadgeMD from '../assets/images/badge md.png';
import FlyMD from '../assets/images/flymd.png';
import SuccessMD from '../assets/images/success md.png';
import MedalMD from '../assets/images/medal md.png';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import EarnRedeemVideos from './EarnRedeemVideos.js';
// import WheelComponent from "react-wheel-of-prizes";
import SharedDialog from '../features/dialogBox/dialogBox'
import { useSelector, useDispatch } from 'react-redux';
import { openDialog, closeDialog } from '../features/dialogBox/dialogBoxSlice';
import SpinWheel from '../pages/SpinWheel';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// export interface DialogTitleProps {
//   id: string;
//   children?: React.ReactNode;
//   onClose: () => void;
// }

// function BootstrapDialogTitle(props: DialogTitleProps) {
//   const { children, onClose, ...other } = props;

//   return (
//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }
const Leaderboard = () => {


  const dispatch = useDispatch();
  const wheelDrawer = (id) => {
    dispatch(openDialog(id));
  }

  //wheel Chart//
  const segments = [
    "bad luck",
    "won 70",
    "won 10",
    "bad luck",
    "won 2",
    "won uber pass"
  ];
  const segColors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
  const onFinished = (winner) => {
    console.log(winner);
  };
  //

  //play now dialog//
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  //
  //dialog//
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const moreDialogClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  ////////////////
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));
  ///
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  //search field//

  //search bar menu //
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '36px',
    backgroundColor: '#EDF5FE',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


  return (
    <div>
      <Box sx={{ width: '1', typography: 'body1', mb:'1rem' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<Typography variant='body1' fontWeight='var(--font-weight-5)'>Leaderboard</Typography>} sx={{color:'var(--color-lightgrey)'}} value="1" />
              <Tab label={<Typography variant='body1' fontWeight='var(--font-weight-5)'>Earn & Redeem</Typography>}  value="2" sx={{color:'var(--color-lightgrey)'}} />
              {/* <Tab label='Leaderboard'  value="1" />
              <Tab label='Earn & Redeem' value="2" /> */}
            </TabList>
          </Box>
          {/* /////////////////////////////   LEADERBOARD     /////////////////////////// */}
          <TabPanel value="1" sx={{ p: '0', mt: 'var(--margin-top-leaderboard-tab)' }}>
            <Stack spacing={2}>
            {/*, filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))' */}
              <Card sx={{ p: 2, boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <Grid container spacing={2}>
                  <Grid item lg={8} md={12}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6" fontWeight="var(--font-weight-4)" color="text.primary" mt={2}>
                        Hello <b>Alina,</b> Welcome back to the Leaderboard
                      </Typography>
                      <Box sx={{ mr: 2 }}>
                      <Typography variant="h5" fontWeight="var(--font-weight-5)" color="var(--color-leaderboard-title)" sx={{display:"flex", flexWrap: "wrap" }}>
                          Congratulation! You have won
                          <Box display="flex" columnGap="var(--column-gap-half)" alignItems="center" ml='var(--margin-left-0_5)' md={12}>
                            <Box display="flex" m="var(--margin-leaderboard)">
                              <img src={Coin} alt="coin-logo" className="leaderboardImg" />
                            </Box>
                            <Box color="Black">
                              <b>48</b>
                            </Box>
                            <Typography variant='h6' fontWeight='var(--font-weight-4)' color="Black" >Coins</Typography>
                            <Box display="flex" m="var(--margin-leaderboard)">
                              <img src={Badge} alt="React Logo" className="leaderboardImg" />
                            </Box>
                            <Box color="Black">
                              <b>1</b>
                            </Box>
                            <Typography variant='h6' fontWeight='var(--font-weight-4)' color="Black" >Badges</Typography>
                          </Box>
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* /////////////////////////////////////////////////////////////////////////// */}
                  {/* ///////////////////////     SPINNING WHEEL     ////////////////////////////// */}
                  <Grid item lg={4} md={12}>

                    <Stack spacing={2} sx={{justifyContent:{md:'left', lg:'right'} }} direction="row">
                      <Item sx={{ display: 'flex', backgroundColor: 'var(--color-leaderboard-title)', borderRadius: 'var(--border-radius-leaderboard-wheel)' }}>
                        <Stack direction="column" sx={{justifyContent:'space-evenly'}}  spacing={0.7} pl={1} pr={1} xs={8}>
                          <Typography variant='h6' color="white" fontWeight="var(--font-weight-leaderboard)" sx={{lineHeight:'1rem'}}>Spin & Win rewards</Typography>
                          <Typography variant='caption' color="var(--color-white)" fontWeight="var(--font-weight-3)">Play game and win exciting rewards</Typography>
                          <Box>
                            <Button size="medium" variant="contained" endIcon={<ChevronRightIcon />} sx={{ backgroundColor: "#5a84fa", borderRadius: '20px', boxShadow: "none", fontSize: "0.75rem" }} onClick={() => wheelDrawer('wheelDialog')}>
                              Play Now
                            </Button>
                          </Box>
                          <SharedDialog id='wheelDialog'>
                            <SpinWheel />
                          </SharedDialog>
                        </Stack>
                        <Stack>
                          <Box sx={{ display: "inline" }}>
                            <img src={SpinningWheel} alt="React Logo" />
                          </Box>
                        </Stack>

                      </Item>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* ///////////////////////////////////////////////////////////////////////////////*/}
              {/* ///////////////////////     LEADERBOARDTAB     ////////////////////////////// */}
              <Item sx={{ p: 2, borderRadius:'12px', border:'1px solid rgba(0,0,0,0.1)'}}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={8} lg={9} sx={{alignItems:'center'}}>
                    <Typography variant="h6" fontWeight='bold' color="#000000">Leaderboard</Typography>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <Search>
                      <SearchIconWrapper sx={{ color:'#64748B' }}>
                        <SearchIcon />
                      </SearchIconWrapper>
                      <StyledInputBase
                        // sx={{borderRadius:'30px',backgroundColor:"#EEF6FF"}}
                        placeholder="Search here…"
                        inputProps={{ 'aria-label': 'search' }}
                      />
                    </Search>
                  </Grid>
                </Grid>
                <Box sx={{ my: 2 }}>
                  <LeaderboardTab />
                </Box>

              </Item>
            </Stack>
          </TabPanel>
          {/* ///////////////////////////////////////////////////////////////////////////////*/}
          <TabPanel value="2" sx={{ p: '0', mt: 'var(--margin-top-leaderboard-tab)' }}>
            <Stack spacing={2}>
              <Card sx={{ p: 2, boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}>

                <Grid container spacing={2}>
                  <Grid item lg={8} md={12}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6" fontWeight="var(--font-weight-4)" color="text.primary" mt={2}>
                        Hello <b>Alina,</b> Welcome back to the Leaderboard
                      </Typography>
                      <Box>
                      <Typography variant="h5" fontWeight="var(--font-weight-5)" color="var(--color-leaderboard-title)" sx={{display:"flex", flexWrap: "wrap" }}>
                          Congratulation! You have won
                          <Box display="flex" columnGap="var(--column-gap-half)" alignItems="center" ml='var(--margin-left-0_5)' md={12}>
                            <Box display="flex" m="var(--margin-leaderboard)">
                              <img src={Coin} alt="coin-logo" className="leaderboardImg" />
                            </Box>
                            <Box color="Black">
                              <b>48</b>
                            </Box>
                            <Typography variant='h6' fontWeight='var(--font-weight-4)' color="Black" >Coins</Typography>
                            <Box display="flex" m="var(--margin-leaderboard)">
                              <img src={Badge} alt="React Logo" className="leaderboardImg" />
                            </Box>
                            <Box color="Black">
                              <b>1</b>
                            </Box>
                            <Typography variant='h6' fontWeight='var(--font-weight-4)' color="Black" >Badges</Typography>
                          </Box>
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* /////////////////////////////////////////////////////////////////////////// */}
                  {/* ///////////////////////     SPINNING WHEEL     ////////////////////////////// */}
                  <Grid item lg={4} md={12}>

                    <Stack spacing={2} sx={{justifyContent:{md:'left', lg:'right'} }} direction="row">
                      <Item sx={{ display: 'flex', backgroundColor: 'var(--color-leaderboard-title)', borderRadius: 'var(--border-radius-leaderboard-wheel)' }}>
                        <Stack direction="column" sx={{justifyContent:'space-evenly'}}  spacing={0.7} pl={1} pr={1} xs={8}>
                          <Typography variant='h6' color="white" fontWeight="var(--font-weight-leaderboard)" sx={{lineHeight:'1rem'}}>Spin & Win rewards</Typography>
                          <Typography variant='caption' color="var(--color-white)" fontWeight="var(--font-weight-3)">Play game and win exciting rewards</Typography>
                          <Box>
                            <Button size="medium" variant="contained" endIcon={<ChevronRightIcon />} sx={{ backgroundColor: "#5a84fa", borderRadius: '20px', boxShadow: "none", fontSize: "0.75rem" }} onClick={() => wheelDrawer('wheelDialog')}>
                              Play Now
                            </Button>
                          </Box>
                          <SharedDialog id='wheelDialog'>
                            <SpinWheel />
                          </SharedDialog>
                        </Stack>
                        <Stack>
                          <Box sx={{ display: "inline" }}>
                            <img src={SpinningWheel} alt="React Logo" />
                          </Box>
                        </Stack>

                      </Item>
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* /////////////////////////////////////////////////////////////////////////// */}
              <Card sx={{ p: 2, display: "flex", boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Stack width='100%'>
                  <Typography display="flex" color="#222222" variant="h6" >
                    Badges
                  </Typography>
                  <Stack display="flex" direction="row" spacing={2} sx={{my:'1rem', flexWrap: "wrap", justifyContent: "space-between" }} >
                    {/* VIDEO EXPLORER */}
                    <Item sx={{ backgroundColor: '#FBFBFB', boxShadow: 'var(--box-shadow-none)', maxWidth: "var(--max-width-leaderboard-badges)", marginLeft: "0rem !important" }}>
                      <Stack direction="row" spacing={2}>
                        <Box><img src={BadgeMD} alt="React Logo" /></Box>
                        <Box> <Typography color="#000000" variant="body1" fontWeight="Bold" gutterBottom>
                          Video Explorer
                        </Typography>
                          <Typography color="#000000" variant="caption" gutterBottom>
                            You can earn this badge by watching 3 recommended
                            <span onClick={handleClickOpen('paper')} ><Typography variant="body1" fontSize="0.75rem" fontWeight="Bold" cursor="pointer">more</Typography>

                              {/* //  DIALOG BOX - BADGES SECTION */}

                              <Dialog
                                classname="leaderboardBadgesDialog"
                                open={open}
                                onClose={moreDialogClose}
                                scroll={scroll}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                              >
                                <DialogTitle id="scroll-dialog-title">
                                  <Stack direction="row" spacing={2}>
                                    <Box><img src={BadgeMD} alt="React Logo" /></Box>
                                    <Box>
                                      <Typography color="var(--color-black)" variant="body1" fontWeight="bold" gutterBottom>
                                        Video Explorer
                                      </Typography>
                                      <Typography color="#000000" variant="body1" gutterBottom>
                                        You can earn this badge by watching 3 recommended video
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </DialogTitle>
                                <DialogContent dividers={scroll === 'paper'}>
                                  <DialogContentText
                                    color="#000000"
                                    id="scroll-dialog-description"
                                    ref={descriptionElementRef}
                                    tabIndex={-1}
                                  >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem montes, non, sit scelerisque mauris ut pellentesque risus, vivamus. Parturient orci fames in elit mattis molestie. Sed sit posuere eget gravida viverra odio leo enim bibendum. Elementum nullam nunc, dolor volutpat. Maecenas fermentum dui quisque arcu, diam justo. Nibh sem malesuada in duis viverra volutpat enim. Aliquam non, integer nibh at. At mollis turpis lorem leo tortor etiam. Congue imperdiet nunc ac urna urna vitae.
                                    Sed mi vitae orci vestibulum. Nisl eget adipiscing lacus nunc, pulvinar scelerisque nulla tempus. Ullamcorper pellentesque leo venenatis vel sagittis in auctor ac enim. Ullamcorper adipiscing gravida rutrum interdum. Lorem adipiscing sociis egestas nulla nam turpis. Commodo diam suspendisse mi blandit et turpis consectetur. Interdum libero ut augue egestas auctor. Praesent nibh ullamcorper lacus, diam eu nibh ac dolor suscipit.
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button>Close</Button>
                                </DialogActions>
                              </Dialog>

                              {/* //  DIALOG BOX - BADGES SECTION */}

                            </span>
                          </Typography>
                        </Box>
                      </Stack>
                    </Item>

                    {/* FAST COMPLETION */}
                    <Item sx={{ backgroundColor: '#FBFBFB', boxShadow: 'var(--box-shadow-none)', maxWidth: "var(--max-width-leaderboard-badges)", marginLeft: "0rem !important" }}>
                      <Stack direction="row" spacing={2}>
                        <Box><img src={SuccessMD} alt="React Logo" /></Box>
                        <Box> <Typography color="var(--color-black)" variant="body1" fontWeight="var(--font-weight-5)" gutterBottom>
                          Fast Completion
                        </Typography>
                          <Typography color="var(--color-black)" variant="caption" gutterBottom>
                            You can earn this badge by completing information in 24 hour
                          </Typography>
                        </Box>
                      </Stack>
                    </Item>

                    {/* SOCIAL BUTTERFLY */}
                    <Item sx={{ backgroundColor: '#FBFBFB', boxShadow: 'var(--box-shadow-none)', maxWidth: "var(--max-width-leaderboard-badges)", marginLeft: "0rem !important" }}>
                      <Stack direction="row" spacing={2}>
                        <Box><img src={FlyMD} alt="React Logo" /></Box>
                        <Box> <Typography color="var(--color-black)" variant="body1" fontWeight="var(--font-weight-5)" gutterBottom>
                          Social Butterfly
                        </Typography>
                          <Typography color="var(--color-black)" variant="caption" gutterBottom>
                            You can earn this badge by completing 3 social media tasks
                          </Typography>
                        </Box>
                      </Stack>
                    </Item>

                    {/* TOP ACHIEVER */}
                    <Item sx={{ backgroundColor: '#FBFBFB', boxShadow: 'var(--box-shadow-none)', maxWidth: "var(--max-width-leaderboard-badges)", marginLeft: "0rem !important" }}>
                      <Stack direction="row" spacing={2} >
                        <Box><img src={MedalMD} alt="React Logo" /></Box>
                        <Box> <Typography color="var(--color-black)" variant="body1" fontWeight='var(--font-weight-5)' gutterBottom>
                          Top Achiever
                        </Typography>
                          <Typography color="var(--color-black)" variant="caption" gutterBottom>
                            You can earn this badge by completing 3 social media tasks
                          </Typography>
                        </Box>
                      </Stack>
                    </Item>

                  </Stack>

                </Stack>

              </Card>
              <Card sx={{ p: 2, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <EarnRedeemVideos />
                {/* <Stack direction="row" justifyContent="space-between" >
                  <Stack direction="column">
                    <Typography variant="h6" gutterBottom>
                      Recommended videos
                    </Typography>
                    <Typography variant="h8" gutterBottom>
                      You can earn coins and badges by watching recommended videos
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="h8" color="blue" gutterBottom>
                      Watch more
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Item>
                    <Stack direction="column">
                      <Box>wjkd</Box>
                      <Box>fefg</Box>
                      <Box>sdk</Box>
                    </Stack>
                  </Item>
                  <Item> item 2</Item>
                  <Item> item 3</Item>
                  <Item> item 4</Item>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="column">
                    <Typography variant="h6" gutterBottom>
                      Training videos
                    </Typography>
                    <Typography variant="h8" gutterBottom>
                      You can redeem coins and badges for this paid training videos
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography variant="h8" color="blue" gutterBottom>
                      Watch more
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Item> item 1</Item>
                  <Item> item 2</Item>
                  <Item> item 3</Item>
                  <Item> item 4</Item>
                </Stack> */}
              </Card>
            </Stack>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  )


}

export default Leaderboard;