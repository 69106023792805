import React from 'react'
import Coin from '../../assets/images/gold-coin.png';
import Box from '@mui/material/Box';
import Badge from '../../assets/images/badge.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import LeaderboardData from '../../datafiles/Leaderboard/Leaderboard/leaderboardDataTable.json';
import manfollow from '../../assets/images/run.gif'
const LeaderboardTab = () => {
  const LeaderData = [
    { id: 'rank', label: 'Rank', minWidth: 100, template: (row) => {return (<>
    <b className="rank-on-leaderboard">
      {row['rank']}
    </b>
      </>)}},
    { id: 'name', label: 'Name', minWidth: 100,template: (row) => {return (<>
    <Box sx={{display: 'flex', gap:'20%'}}>
      <Box sx={{display: 'flex',alignItems: 'center', justifyContent: 'left'}}>
        {<img className='image-on-leaderboard' src={row.name.profile} />}{<b className="name-on-leaderboard"> {row.name.title} </b>}
      </Box>
      <Box sx={{display: 'flex',alignItems: 'flex-end'}}>
        {
        row.userID === '04' && 
        <Box>
        <img src={manfollow} alt="My Image" style={{scale:'2.8',height:"1rem",width:'2rem',mr:'1rem'}}/>
        </Box>
        }
      </Box>
    </Box>
    </>)}},
    { id: 'badge', label: 'Badge', minWidth: 100,template: (row) => {return (<>
       {row.badge.map((v, i) =>
        
        <img className="badge-on-leaderboard" key={i} alt={v.name} src={v.file} />)}
    </>)}},
    { id: 'coin', label: 'Coin', minWidth: 100, template: (row) => {   
      return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'left'}}>
          {row.coin.type == 'Coin' ? <img className='coin-style coin-on-leaderboard' src={Coin} /> : <img className='coin-style coin-on-leaderboard' src={Badge} />} {<b className="name-on-leaderboard"> {row.coin.value} </b>}
          </Box>
        </>
      )
    }} ,
    { id: 'totalPoint', label: 'Total Points', minWidth: 100, template: (row) => {return(<b className="name-on-leaderboard">{parseInt(row.totalPoint)}</b>)}},
  ];
  
  function createData(rank, name, badge, coin,totalPoint) {
    return { rank, name, badge, coin, totalPoint };
  }
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (
    <div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {LeaderData.map((column, index) => {
                        return (
                <TableCell
                  sx={{backgroundColor:'#EEF6FF'}}
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth , color:"#64748B"}}
                >
                  {column.label}
                </TableCell>
             )
            })}
            </TableRow>
          </TableHead>
          <TableBody>
                  {LeaderboardData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row,index) => {
                      return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {LeaderData.map((column) => {
                      const value = row[column.id];
                      
                      return (
                        <TableCell sx={{p:'.5rem 1rem', borderBottom:'1px solid rgba(0, 0, 0, 0.1) '}} key={column.id} align={column.align}>
                          {
                          column.template
                            ? column.template(row) 
                            : value
                            }
                          
                             
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={LeaderboardData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
  )
}

export default LeaderboardTab