import React from "react";
import Login from "../pages/Login";
import Welcome from '../pages/Welcome';
import Introduction from '../pages/Introduction';
import Dashboard from '../pages/Dashboard';
import EmployeeDetails from '../pages/EmployeeDetails';
// import Teams from '../pages/Teams'
import Leaderboard from '../pages/Leaderboard';
import Videos from '../pages/Videos';
import Profile from '../pages/Profile';
import ChangePassword from '../pages/ChangePassword';  
import HelpCenter from '../pages/HelpCenter';
import Sidebar from '../layouts/sidebar/Sidebar';
import Footer from '../layouts/footer/Footer';
import ThemeProvider from '../theme';
import OrganizationTree from '../components/OrganizationTree/OrganizationTree'

const windowObject = window;
export function PrivateComponent() {
  let response = [
    { url: '/', page: <Login /> },
    { url: '/welcome', page: <Welcome /> },
    { url: '/introduction', page: <Introduction />, },

    { url: '/dashboard', page:  <ThemeProvider>
    <Sidebar window={windowObject}>
      <Dashboard />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: '/employeeDetails', page: <ThemeProvider>
    <Sidebar window={windowObject}>
      <EmployeeDetails />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: 'leaderboard/', page: <ThemeProvider>
    <Sidebar window={windowObject}>
      <Leaderboard />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: '/videos', page:<ThemeProvider>
    <Sidebar window={windowObject}>
      <Videos />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: '/OrganizationTree', page:<ThemeProvider>
      <Sidebar window={windowObject}>
        <OrganizationTree />
      </Sidebar>
      {/* <Footer /> */}
    </ThemeProvider> },

    { url: '/Profile', page: <ThemeProvider>
    <Sidebar window={windowObject}>
      <Profile />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: '/ChangePassword', page: <ThemeProvider >
    <Sidebar window={windowObject}>
      <ChangePassword />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },

    { url: '/HelpCenter', page: <ThemeProvider >
    <Sidebar window={windowObject}>
      <HelpCenter />
    </Sidebar>
    {/* <Footer /> */}
  </ThemeProvider> },
  ]
  return response;
}
