import React,{useState, useRef, useEffect} from 'react'
import {Typography,Box, CardHeader, Card, Avatar, IconButton, 
        VideocamSharp as VideocamSharpIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, PlayArrow as PlayArrowIcon} from '../../utils/AllImportsHelper';
import { Navigation, Pagination,  A11y } from 'swiper';
// import introVideo from '../../assets/video/companyVideo.mp4';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './css/Videos.css'
import { Stack } from '@mui/system';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fab from '@mui/material/Fab';
import CardMedia from '@mui/material/CardMedia';
import AnimatedCoin from '../../assets/images/animatedCoin.gif';
import PlayIcon from '../../assets/images/playicon-vector.svg';
// import VideoThunbnailImg from '../../assets/images/videoThunbnailImg.png';
import mandatoryData from '../../datafiles/Videos/mandatoryVideo.json';
// import Link from '@mui/material/Link';
import { Link } from "react-router-dom";



const Videos = () => {
 


  //SWIPER FOR VIDEOS
  const swiperRef = useRef(null);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    swiper.on('slideChange', () => {
      setIsPrevDisabled(swiper.isBeginning);
      setIsNextDisabled(swiper.isEnd);
    });
  }, []);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  
  return (
    <>

    {/* /////////////////////////////   VIDEOS  ///////////////////////////// */}


      <Card sx={{ minHeight: 'var(--min-height-dashboard-components)', height:1, boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}>
            <CardHeader
              avatar={
                <Avatar sx={{ width:'var(--equal-hw1_5)', height:'var(--equal-hw1_5)', bgcolor: 'var(--bgcolor-policy-avatar)' }}>
                  <VideocamSharpIcon fontSize="small" />
                </Avatar>
              }
              title={
                <Typography variant="h6">Videos</Typography>
              }
              action={
                <Stack direction="row" alignItems="center" mt={1} mr={1} spacing={1}>
                    <Typography variant="body1" pr={1} color='var(--color-blue)'>See All</Typography>
                    <IconButton aria-label="delete" sx={{'&.Mui-disabled':{backgroundColor: '#DBEAFE'},width:'var(--equal-hw1_25)', height:'var(--equal-hw1_25)' ,backgroundColor: 'var(--color-blue)'}} className='swiper-button' disabled={isPrevDisabled} onClick={handlePrevClick}>
                        <ArrowBackIosIcon sx={{width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}}/>{/*ml is directly assigned for temporary purposes */}
                    </IconButton>
                    <IconButton aria-label="delete" sx={{'&.Mui-disabled':{backgroundColor: '#DBEAFE'},width:'var(--equal-hw1_25)', height:'var(--equal-hw1_25)', backgroundColor: 'var(--color-blue)' }} className='swiper-button' disabled={isNextDisabled} onClick={handleNextClick}>
                        <ArrowForwardIosIcon sx={{width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)' }} />
                    </IconButton>
                </Stack>
              }
              // title={<Typography variant="h6" pl={.5}>{heading}</Typography>}
    />    
        <Swiper
          ref={swiperRef}
          // install Swiper modules
          modules={[Navigation, Pagination, A11y]}
          
          breakpoints={{
            599: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            899: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1199: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1399: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            1599: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }}
          // breakpoints = {{
          // 599 : {
          //   slidesPerView: 2,
          //   spaceBetween:20
          // },
          // 899 : {
          //   slidesPerView: 3,
          //   spaceBetween:20
          // },
          // 1199 : {
          //   slidesPerView: 3,
          //   spaceBetween:20
          // },
          // 1399 : {
          //   slidesPerView: 3,
          //   spaceBetween:20
          // },
          // 1599 : {
          //   slidesPerView: 4,
          //   spaceBetween:20
          // }
          // }}
          navigation
          // showsPagination={false} 
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >
        {
            mandatoryData.map((path, index) => {
              return (
                <SwiperSlide key={index}>

                <Card sx={{ padding:'2.5%', width:'100%'}} lg={4} md={6} sm={12}>
                <Link className="disable-link-styles" to="/Videos">
                    <CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%'}} 
                      component='video'
                      poster={path.video}
                      src={path.videos}
                    />

                    <Fab
                      sx={{
                      position: 'absolute',
                      top:{xs: '32%',sm:'38%', md:'35%', lg:'32%'},
                      left:{xs: '50%'},
                      transform: 'translate(-50%, -50%)',
                      width:'3rem',
                      height:'3rem'
                    }} color="primary" aria-label="play arrow">
                      <PlayArrowIcon />
                    </Fab>

                    <CardContent sx={{padding:".25rem 0rem"}}>
                      <Typography variant="subtitle2" fontWeight="var(--font-weight-5)">
                        {path.title}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {path.subtitle}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{height:'2.5rem', justifyContent:'space-between', p:'0 .2rem'}}>
                      <Box>
                      <Typography display="inline-flex" fontWeight="var(--font-weight-4)" variant="caption">{path.views} Views</Typography>
                      </Box>
                      <Box color="var(--color-mui-primary-blue)" display="inline-flex" sx={{alignItems:'center'}}>
                        <Typography variant="caption">Earn</Typography>
                        <Box display="inline-flex" sx={{ width:"var(--equal-hw0_75)",height:"var(--equal-hw0_75)",scale:'2.8', m:'0rem .3rem'}}><img src={AnimatedCoin} alt="coin"/></Box>
                        <Typography variant="caption">{path.earn} coins</Typography>
                      </Box>
                      
                    </CardActions>
                    </Link>
                  </Card>
                </SwiperSlide>
              )
            })
          }
        {/* {
            mandatoryData.map((path, index) => {
              return (
                <SwiperSlide key={index}>
                <Link to="/Videos">
                        <img  
                          className="videos-dashboard"
                          src={path.video}
                          alt="thumbnail"
                        />
                </Link>
                </SwiperSlide>
            )})
          } */}

          {/* {
            videoData.map((path, index) => {
              return (
                <SwiperSlide key={index}>
                        <video controls
                          poster={VideoThunbnailImg}
                          className="videos-dashboard">
                          <source src={path.src} type="video/mp4"></source>
                        </video>
                </SwiperSlide>
            )})
          } */}
        </Swiper>
      </Card>





{/*  */}
    </>
  )
}

export default Videos
