import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
// import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Header from '../header/Header'
import Footer from '../../layouts/footer/Footer';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupsIcon from '@mui/icons-material/Groups';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useDispatch, useSelector } from 'react-redux';
import {setCurrentRoute} from '../../features/sidebarRouting/sidebarSelectionSlice';
import './Sidebar.css'

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    // height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
    height:'100%',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    // position: 'relative',
    top: 61,
    paddingRight: 15,
});

const closedMixin = (theme) => ({
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${theme.spacing(9)})`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    borderRight: 0,
    background: '#ffffffbf',
    top: 63,
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('xs')]: {
        width: `calc(${theme.spacing(0)} + 1px)`,
    },
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    [theme.breakpoints.up('lg')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => (prop !== 'open' || prop !== 'mobileOpen'),
})(({ theme, open,mobileOpen }) => ({
    // zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...((open || mobileOpen) && {
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) =>  (prop !== 'open' || prop !== 'mobileOpen') })(
    ({ theme, open,mobileOpen }) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex:2,
        boxSizing: 'border-box',
        ...((open || !mobileOpen) && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...((!open || mobileOpen) && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Sidebar({ children,...props }) {
    // const theme = useTheme();
    // const { open } = props;
    const [open, setOpen] = React.useState(true);
    // const [currentRoute, setcurrentRoute] = useState(0);
    const {currentRoute} = useSelector(state => state.routing)
    // console.log("saurabh the coder",currentRoute)
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const dispatch = useDispatch();
    
    // const currentRoute = () => {
    //     dispatch(setCurrentRoute(path));
    //   };
    // const dialogContent = (id) => {
    //   dispatch(setCurrentRoute(text.path))
    // } , []);

    const handleDrawerOpen = () => {
        // handleDrawerToggle();
        if (open === false) {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
        console.log({open})
    };
  
    const handleDrawerToggle = () => {
         // handleDrawerToggle();
         if (mobileOpen === false) {
            setMobileOpen(true);
        }
        else {
            setMobileOpen(false);
        }
        // console.log({open})
    };
    
    const container = window !== undefined ? () => window.document.body : undefined;

    const itemsList = [
        {
            label: "Dashboard",
            icon: <DashboardIcon />,
            path: "/dashboard"
        },
        {
            label: "Employee Details",
            icon: <AccountBoxIcon />,
            path: "/employeeDetails"
        },
        {
            label: "Teams",
            icon: <GroupsIcon />,
            path: "/OrganizationTree"
        },
        {
            label: "Videos",
            icon: <PlayCircleOutlineIcon />,
            path: "/Videos"
        },
        {
            label: "Leaderboard",
            icon: <LeaderboardIcon />,
            path: "/leaderboard"
        },
    ];

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const handleListItemClick = (e, path) => {
        // setcurrentRoute(path);
        dispatch(setCurrentRoute(path));
    };

    return (
            <Box>
                    <Box sx={{ display: 'flex'}}>
                        <AppBar position="fixed" open={mobileOpen} sx={{ background: "#EEF6FF", boxShadow:'none'}}>
                            <Toolbar>
                                <IconButton
                                    color="black"
                                    aria-label="open drawer"
                                    onClick={ (window.innerWidth > 600) ? handleDrawerOpen : handleDrawerToggle}
                                    edge="start"
                                    sx={{
                                        marginRight: 0,
                                        ...(open),
                                        color: '#000000',
                                    display: { xs: 'block', sm:'block' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box'},
                                    }}
                                >
                                    {/* <MenuIcon /> */}
                                    <MenuOpenIcon />
                                </IconButton>
                                <Header />
                            </Toolbar>
                        </AppBar>

                    <Drawer
                        className = "sidebar-drawer"
                        variant={(window.innerWidth > 600) ? "permanent" : "temporary" }
                        open={(window.innerWidth > 600) ? open : mobileOpen}  
                        onClose={(window.innerWidth > 600) ? handleDrawerOpen : handleDrawerToggle }
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}


                    sx={{
                        top: 60,
                        // display: { xs: 'none', sm:'block' },
                        backgroundColor: "#ffffffbf",
                        '& .MuiDrawer-paper': { boxSizing: 'border-box'},
                    }}
                    
                        >
                        <List sx={{ textAlign: '-webkit-center' }}>
                                {itemsList.map((text, index) => (
                                    <ListItem key={text.label} disablePadding sx={{ display: 'block' }}
                                        component={Link}
                                        to={text.path}
                                        onClick={(e) => handleListItemClick(e, text.path)}
                                        className={(mobileOpen || open) ? "open-selected-tab" : "close-selected-tab"}
                                        
                                        style={
                                            currentRoute === text.path
                                                ? {
                                                    background: '#DBEAFE',
                                                    // boxShadow: "2px 3px 6px rgba(0, 0, 0, 0.3)",
                                                }
                                                : {}
                                        }
                                    >
                                        <ListItemButton sx={{ minHeight: 48,justifyContent: (mobileOpen || open) ? 'initial' : 'center', px: 2.5,}}>
                                            <ListItemIcon style={currentRoute === text.path ? { color: "#3C82F5" } : { color: '#96A5B8' }}
                                                sx={{ minWidth: 0, mr: (mobileOpen || open) ? 3 : 'auto', justifyContent: 'center'}}>
                                                {text.icon}
                                            </ListItemIcon>
                                            <ListItemText disableTypography primary={<Typography variant="body1" style={currentRoute === text.path ? { color: 'rgba(4, 5, 7, 0.87)', fontWeight: 700 } : { color: '#000000' }}>{text.label}</Typography>} sx={{ opacity: (mobileOpen || open) ? 1 : 0 }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                    </Drawer>
                    <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, pt: 8, pl: 2, pr: 2, background: '#EEF6FF',minHeight: '92vh', minWidth: 0 }}>
                        {children}
                    </Box>
                    </Box>
                    <Grid item xs={12}>
                        <AppBar position ="static" sx={{background: "#FFFFFF"}}>
                            <Footer/>
                        </AppBar>
                    </Grid>
            </Box>
    );
}