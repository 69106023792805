import React, {useState, useEffect} from "react";
import "../assets/styles/Theme.css";
import Footer from "../layouts/footer/Footer";
import Box from "@mui/material/Box";
import Header from "../layouts/header/Header";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Coin from '../assets/images/gold-coin.png';
import Badge from '../assets/images/badge.png';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Datas from '../datafiles/introduction/todoTaskListDummyData.json';
import { AppBar } from '@mui/material';
import ApiService from '../utils/ApiService';



// const DashboardData = [
//   { id: 'sequence', label: 'S.No', minWidth: 5, borderBottom:'none' },
//   { id: 'name', label: 'Tasks', minWidth: 10, borderBottom:'none' },
//   { id: 'endDate', label: 'Days Left', minWidth: 30, borderBottom:'none' },
//   { id: 'reward', label: 'Rewards', minWidth: 50, template: true, borderBottom:'none' },
// ];

const DashboardData = [
  { id: 'srNo', label: 'S.No', minWidth: 5, borderBottom:'none' },
  { id: 'task', label: 'Tasks', minWidth: 10, borderBottom:'none' },
  { id: 'daysLeft', label: 'Days Left', minWidth: 30, borderBottom:'none' },
  { id: 'reward', label: 'Rewards', minWidth: 50, template: true, borderBottom:'none' },
];

const Introduction = () => {

  const [taskList, setTaskList] = useState([]);

  useEffect(() => {
  //   ApiService.get('task', {}).then(res => {
  //     // setLoading(false)
  //     let data = res.data.data
  //     console.log({res,data});
  //     if (data) {
  //       setTaskList(data);
  //         // let reverseData = _.orderBy(data, ['type'], ['asc']);
  //         // setDashboardList(reverseData)
  //     }
  // }).catch((error) => {
  //     // setLoading(false)
  //     console.log(error);
  // })
    setTaskList(Datas)
  }, [])
  
  return (
    <div>
      <Box>
        <Header />
      </Box>
      <Box sx={{ p:"3rem" ,minHeight:"92vh", display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
        <Card sx={{background:'#FFFFFF', boxShadow: '0px 18px 47px rgba(0, 0, 0, 0.15)',borderRadius: '4px'}}>
          <CardContent>
            <Box sx={{ flexGrow: 1, overflow: "hidden", px: 2 }}>
              <Box>
                {/* <Stack spacing={3} direction="row" sm={12} justifyContent="space-between"> */}
                  <Grid container direction="row" spacing={1}  justifyContent="space-between">
                    <Grid item xs={12} sm={9} md={9} lg={10}>
                     
                          <div><Typography variant="h6">Welcome Alina!</Typography></div>
                          <div>Your checklist of Onboarding activities and tasks, complete them to earn coins
                            <span className='icon-position'><img src={Coin} alt="React Logo" /></span>
                            badges
                            <span className='icon-position'><img src={Badge} alt="React Logo" /></span> and other rewards.</div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={2}>
                          {/* <Box  sx={{xs: 'textAlign' ? 'start' : 'end'}}> */}
                          <Box className="dashboardStyle">
                            <Link to="/dashboard" className='MuiLink-underlineNone'>
                              <Button sx={{mt:'0.5rem'}} variant="contained" size="small" >
                                GO TO DASHBOARD
                              </Button>
                            </Link>
                          </Box>
                        </Grid>
                  </Grid>

                {/* </Stack> */}
              </Box>
              <Box sx={{mt:2}}>
                <TableContainer sx={{
                  height: 1,
                  borderRadius: 3,
                }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                      <TableRow>
                        {DashboardData.map((column, index) => {
                          return (
                            <TableCell
                              key={index}
                              task={column.id}
                              style={{ minWidth: column.minWidth, borderBottom: column.borderBottom }}
                              sx={{
                                backgroundColor: '#E7F1FF'
                              }}
                            >
                              {column.label}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        backgroundColor: '#C9C9C91A'
                      }}>
                      {taskList
                        .map((row, index) => {
                          return (
                            <TableRow role="checkbox" tabIndex={-1} key={index}>
                              {DashboardData.map((column, index) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={index} align={column.align} style={{borderBottom: column.borderBottom}}
                                  >
                                    {
                                      column.format
                                        ? column.format(value)
                                        : column.template ? <>
                                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', columnGap: "0.5rem" }}>
                                            {!row.reward ? '' : row.reward.type === 'Coin' ? <img className='coin-style' alt="Coin" src={Coin} /> : <img className='coin-style' alt="Badge" src={Badge} />} {!row.reward ? '-' : row.reward.value}
                                          </Box>
                                        </> : value
                                    }
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            {/* <Box>
            <TableContainer sx={{
                height: 1,
                borderRadius: 3,
              }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead >
                    <TableRow>
                      {DashboardData.map((column, index) => {
                        return (
                          <TableCell
                            key={index}
                            task={column.id}
                            style={{ minWidth: column.minWidth }}
                            sx={{
                              backgroundColor: '#E7F1FF'
                            }}
                          >
                            {column.label}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: '#C9C9C91A'
                    }}>
                    {Datas
                      .map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {DashboardData.map((column, index) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={index} align={column.align}
                                >
                                  {
                                    column.format 
                                      ? column.format(value)
                                      : column.template ? <>
                                      <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'left', columnGap:"0.5rem"}}>
                                        {row.reward.type === 'Coin' ? <img className='coin-style' alt="Coin" src={Coin} /> : <img className='coin-style' alt="Badge" src={Badge} />} {row.reward.value}
                                      </Box>
                                      </> : value
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Box> */}
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default Introduction;
