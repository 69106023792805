import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { closeDialog } from './dialogBoxSlice';

function SharedDialog({ children, ...props }) {
  const dispatch = useDispatch();
  const id = props?.id;
  const isOpen = useSelector((state) => (state.dialog.id === id ? state.dialog.isOpen : false));
  const handleDialogClose = (event, reason) => {
    if (reason && reason != '"backdropClick"')
    return;
    dispatch(closeDialog(id));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="dialog-title"
    >
      {children}
    </Dialog>
  );
}

export default SharedDialog;
