import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { openDialog, closeDialog } from "../../features/dialogBox/dialogBoxSlice";
import { Paper, styled } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
// import ProfileImage from '../../assets/images/Avtaar.png';
import Avatar from '@mui/material/Avatar';
// import CropProfilePicture from './CropProfilePicture';
import { useSelector, useDispatch } from 'react-redux';
import SharedDialog from '../../features/dialogBox/dialogBox';
import ImageCrop from '../../libs/ImageCrop';
import { setOpen, setImage } from "../../features/uploadPic/uploadSlice";
import { setProfileImage } from '../../features/commonDataSlice/commonSlice';
import Link from '@mui/material/Link';

const ChangeProfileDialog = ({ parentId }) => {
  const dispatch = useDispatch();
  const { open, image } = useSelector((state) => state.imageCropper);
  
  const dialogContent = (id) => {
    // dispatch(openDialog(id));
    // console.log(openDialog(id))
    dispatch(setImage(image));
    dispatch(setOpen(true));
  }
  const handleDialogClose = () => {
    dispatch(closeDialog(parentId));
  };

  const handleSave = () => {
    // Dispatch the action to update the user's profile picture
    dispatch(setProfileImage(image));
    // Close the dialog
    dispatch(closeDialog(parentId));
  };
  // const {image} = useSelector(state => state.profile);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  /////////profile update///////////////
  // const [img, setImg] = React.useState(ProfileImage)
  const handleImageUpload = (event) => {
    console.log("ksdhfhsh")
    // Get the selected file from the input element
    var file = event.target.files[0];
    console.log(file)
    // Use the FileReader API to read the file and convert it to a data URL
    var reader = new FileReader();

    reader.onload = function (event) {
      ////////////// Get the data URL from the reader result
      var dataUrl = event.target.result;

      console.log(dataUrl)
      // setImg(dataUrl)
      dispatch(setImage(dataUrl));
      dispatch(setOpen(true));
    };
    reader.readAsDataURL(file);
  }
  return (
    <Box pb="1rem">
      <DialogTitle id="dialog-title" sx={{ backgroundColor: 'var(--color-lightblue)', padding:'var(--padding-none)' }}>
       <Stack sx={{ backgroundColor: "var(--color-lightblue)"}}>
                  <Box sx={{p:'0.25rem 1rem'}}>
                      <Grid container direction="row" alignItems="center">
                          <Grid item xs={10}>
                              <Box pl='1rem' display='flex' sx={{flexDirection:'column'}}>
                                  <Typography variant="body1" color="text.primary" fontWeight="var(--font-weight-7)" display="block" >Change Profile Picture </Typography>
                              </Box>
                          </Grid>
                          <Grid item xs={2} sx={{display:'flex', justifyContent:'flex-end'}}>
                              <IconButton aria-label="upload picture" component="label" onClick={handleDialogClose} color='var(--color-black)'>
                                  <CloseIcon sx={{color:'var(--color-black)'}}/>
                              </IconButton>
                          </Grid>
                      </Grid>
                  </Box>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <Box>
            <Stack direction="column">
              <Box>
                <Avatar src={image} sx={{ height: 'var(--equal-hw8)', width: 'var(--equal-hw8)' }}>
                  {/* <img  alt="Selected profile image" height={ 'var(--equal-hw8)'} width= {'var(--equal-hw8)'} /> */}
                </Avatar>
              </Box>
              <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                <Stack direction="row" >
                  <Box>
                    <IconButton aria-label="delete">
                      <DeleteIcon sx={{ fontSize: 'large' }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton aria-label="edit" onClick={() => dialogContent('cropProfilePicture')}>
                      <EditIcon sx={{ fontSize: 'large' }} />
                    </IconButton>
                    {/* <SharedDialog id='cropProfilePicture'>
                      <Box >
                        <CropProfilePicture />
                      </Box>
                    </SharedDialog> */}
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Box>
              <Item sx={{ 
                width:"20rem",
                height:"10rem",
                display:'flex',alignItems:'center',
                justifyContent:'center',
                flexDirection:'column',
                border: '2px dashed rgba(0, 0, 0, 0.12)',
                borderRadius: '12px', boxShadow: "var(--box-shadow-none)",
                backgroundColor: "rgba(201, 201, 201, 0.1)",
                ':hover': { backgroundColor: "#DBEAFE", borderColor:"var(--color-mui-primary-blue)" } }}>
                <Box>
                  <IconButton color="primary" aria-label="upload picture" component="label" size='small' >
                    <UploadFileIcon />
                  </IconButton>
                </Box>
                <Box className="upload-btn-wrapper">
                  <Box display="inline-flex">
                    <Button className="btn" display='inline-flex'><Typography variant="body1" sx={{textDecoration:'underline'}}>Click to upload</Typography></Button> 
                     <input type="file" name="myfile" onChange={handleImageUpload} />
                  </Box>
                  <Box display='inline-flex'>
                    <Typography variant='body1' textAlign='center' color='var(--color-black)'> or drag and drop  </Typography>
                  </Box>
                </Box>
              </Item>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button variant="text" onClick={handleDialogClose}>
          CANCEL
        </Button>
        <Button variant="contained" onClick={handleSave}>
          SAVE
        </Button>
      </DialogActions>
      <ImageCrop/>
    </Box>
  )
}

export default ChangeProfileDialog