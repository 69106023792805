import React, {useState,useEffect,useRef}  from 'react'
import {
  Typography, CardHeader, Card, Avatar, IconButton, Button,
  VideocamSharp as VideocamSharpIcon, ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon, blue,PlayArrow as PlayArrowIcon 
} from '../utils/AllImportsHelper';
import { Navigation, Pagination, A11y } from 'swiper';
import introVideo from '../assets/video/companyVideo.mp4';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../components/dashboardPages/css/Videos.css'
import { Stack } from '@mui/system';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fab from '@mui/material/Fab';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AnimatedCoin from '../assets/images/animatedCoin.gif';
import mandatoryData from '../datafiles/Videos/mandatoryVideo.json';
import trainingData from '../datafiles/Videos/trainingVideo.json';
import PlayIcon from '../assets/images/playicon-vector.svg';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Link } from 'react-router-dom';
import ArrowFunction from '../components/video/ArrowFunction';

const EarnRedeemVideos = () => {
  const coinsWeHave = 48;
  const videoData = [
    { id: 1, src: introVideo },
    { id: 2, src: introVideo },
    { id: 3, src: introVideo },
    { id: 4, src: introVideo },
    { id: 5, src: introVideo },
    { id: 6, src: introVideo },
    { id: 7, src: introVideo },
    { id: 8, src: introVideo },
  ];

  const renderFunc2 = (path, i) => {

  return (
    <Link className="disable-link-styles" to="/Videos">
                <Card sx={{ padding:'2.5%', width:'100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} lg={4} md={6} sm={12}>
                    {(coinsWeHave < path.cost) ?
                    (<CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%', filter:'grayscale(100%)' }} 
                      component='video'
                      
                      poster={path.video}
                      src={path.videos}
                    />) : 
                    (<CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%', }} 
                      component='video'
                      poster={path.video}
                      src={path.videos}
                    />)
                    }
                    { 
                    (coinsWeHave < path.cost) ? 
                      (<Box className="overlay-lockicon-banner" sx={{display:'flex', justifyContent:'center',alignItems:"center"}}>
                         <LockOutlinedIcon fontSize='large' className="lock-icon"/></Box>)  : 
                      (<Fab 
                        className="play-icon" 
                        sx={{
                        position: 'absolute',
                        top: '32%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'}} 
                        color="primary" 
                        aria-label="play arrow">
                      <PlayArrowIcon />
                      </Fab>) 
                    }

                    <CardContent sx={{padding:".5rem 0rem"}}>
                      <Typography textAlign='left' variant="body1" fontWeight="var(--font-weight-5)">
                        {path.title}
                      </Typography>
                      <Typography textAlign='left' variant="body1" color="text.secondary">
                        {path.subtitle}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{height:'2rem', justifyContent:'space-between', p:'0'}}>
                      <Box color="var(--color-mui-primary-blue)" display="inline-flex" sx={{alignItems:'center'}}>
                        <Typography variant="body2">Redeem for</Typography>
                        <Box display="inline-flex" sx={{ width:"var(--equal-hw1)",height:"var(--equal-hw1)",scale:'2.4', m:'0rem .3rem'}}><img src={AnimatedCoin} alt="coin"/></Box>
                        <Typography variant="body2">{path.cost}</Typography>
                      </Box>
                      <Box>
                      { (coinsWeHave < path.cost) ? (<Typography display="inline-flex" variant="body2">{coinsWeHave}/{path.cost}</Typography>) : (<Button variant="contained">REDEEM</Button>)}
                      </Box>
                    </CardActions>
                  </Card>
                  </Link>
    )

  }
  const renderFunc1 = (path, i) => {

  return (
    <Link className="disable-link-styles" to="/Videos">
    <Card sx={{ padding:'2.5%', width:'100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)'}} lg={4} md={6} sm={12}>
        <CardMedia
          sx={{ borderRadius: 1,
              width: '100%',
              }} 
          component='video'
          poster={path.video}
          src={path.videos}
        />
        <Fab 
          className="play-icon"
          sx={{
          position: 'absolute',
          top: '32%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }} color="primary" aria-label="play arrow">
          <PlayArrowIcon />
        </Fab>
        {/* <Box className="overlay-lockicon-banner" sx={{display:'flex', justifyContent:'center',alignItems:"center"}}>
        <img className="play-icon" src={PlayIcon} alt="play" />
        </Box> */}
        <CardContent sx={{padding:".5rem 0rem"}}>
          <Typography textAlign='left' variant="body1" fontWeight="var(--font-weight-6)">
            {path.title}
          </Typography>
          <Typography textAlign='left' variant="body1" color="text.secondary">
            {path.subtitle}
          </Typography>
        </CardContent>
        <CardActions sx={{height:'2rem', justifyContent:'space-between', p:'0'}}>
          <Box>
          <Typography display="inline-flex" variant="body2">{path.views} Views</Typography>
          </Box>
          <Box color="var(--color-mui-primary-blue)" display="inline-flex" sx={{alignItems:'center'}}>
            <Typography variant="body2">Earn</Typography>
            <Box display="inline-flex" sx={{ width:"var(--equal-hw1)",height:"var(--equal-hw1)",scale:'2.4', m:'0rem .3rem'}}><img src={AnimatedCoin} alt="coin"/></Box>
            <Typography variant="body2">{path.earn} coins</Typography>
          </Box>
        </CardActions>
      </Card>
      </Link>
    )

  }
  return (
    <>

      {/* /////////////////////////////   RECOMMENDED VIDEOS  ///////////////////////////// */}


      {/* <Card sx={{ borderRadius: 2, minHeight: '25rem', height: '100%', boxShadow: 'none' }}>
        <CardHeader sx={{ pb: 1.2, pt: 1.5 }}
          avatar={
            <Avatar sx={{ width: 24, height: 24, bgcolor: blue[500] }}>
              <VideocamSharpIcon fontSize="small" />
            </Avatar>
          }

          action={
            <Stack direction="row" alignItems="flex-end" mt={1} mr={1} spacing={1}>
            <Typography variant="body1" color='#2563EB'>See All</Typography>
            <IconButton aria-label="delete" className='swiper-button' disabled={isPrevDisabled} onClick={prevHandler1}>
              <ArrowBackIosIcon fontSize="large" sx={{ width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}} />
            </IconButton>
            <IconButton aria-label="delete" className='swiper-button' disabled={isNextDisabled} onClick={nextHandler1}>
              <ArrowForwardIosIcon fontSize="large" sx={{ width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}} />
            </IconButton>
          </Stack>  
          }
          title={<Typography variant="h6">Recommended Videos</Typography>}
        />
        <Swiper
          ref={swiperRef1}
          onSwiper={(swiper) => setSwiperRef1(swiper)}
          // install Swiper modules
          modules={[Navigation, Pagination, A11y]}
          breakpoints={{
            599: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            899: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1199: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            1399: {
              slidesPerView: 4,
              spaceBetween: 20
            },
            1599: {
              slidesPerView: 3,
              spaceBetween: 40
            }
          }}
          navigation
          // showsPagination={false} 
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >

{
            mandatoryData.map((path, index) => {
              return (
                <SwiperSlide key={index}>
                <Link className="disable-link-styles" to="/Videos">
                <Card sx={{ padding:'2.5%', width:'100%'}} lg={4} md={6} sm={12}>
                    <CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%',
                          }} 
                      component='video'
                      poster={path.video}
                      src={path.videos}
                    />
                    <Fab 
                      className="play-icon"
                      sx={{
                      position: 'absolute',
                      top: '35%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)'
                    }} color="primary" aria-label="play arrow">
                      <PlayArrowIcon />
                    </Fab>
                    {/* <Box className="overlay-lockicon-banner" sx={{display:'flex', justifyContent:'center',alignItems:"center"}}>
                    <img className="play-icon" src={PlayIcon} alt="play" />
                    </Box> */}
                   {/*} <CardContent sx={{padding:".5rem 0rem"}}>
                      <Typography variant="body1" fontWeight="var(--font-weight-5)">
                        {path.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {path.subtitle}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{height:'2rem', justifyContent:'space-between', p:'0'}}>
                      <Box>
                      <Typography display="inline-flex" variant="body2">{path.views} Views</Typography>
                      </Box>
                      <Box color="var(--color-mui-primary-blue)" display="inline-flex" sx={{alignItems:'center'}}>
                        <Typography variant="body2">Earn</Typography>
                        <Box display="inline-flex" sx={{ width:"var(--equal-hw1)",height:"var(--equal-hw1)",scale:'2.4', m:'0rem .3rem'}}><img src={AnimatedCoin} alt="coin"/></Box>
                        <Typography variant="body2">{path.earn} coins</Typography>
                      </Box>
                    </CardActions>
                  </Card>
                  </Link>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </Card> */}

        {/* mandatoryData.map((path, index) => {
        return ( */}
        <Box>
          {/* {console.log(v,categories[v])} */}
          <ArrowFunction data={mandatoryData} renderFunc={renderFunc1} heading={"Recommended Videos"} subHeading={"You can earn coins and badges by watching recommended videos"} />
        </Box>
    


      {/* //////////////////////////    TRAINING VIDEOS  /////////////////////////////  */}


      {/* <Card sx={{ borderRadius: 2, minHeight: '25rem', height: '100%', boxShadow: 'none' }}> */}
        {/* <CardHeader sx={{ pb: 1.2, pt: 1.5 }}
          avatar={
            <Avatar sx={{ width: 24, height: 24, bgcolor: blue[500] }}>
              <VideocamSharpIcon fontSize="small" />
            </Avatar>
          }

          action={
            <Stack direction="row" alignItems="flex-end" mt={1} mr={1} spacing={1}>
            <Typography variant="body1" color='#2563EB'>See All</Typography>
            <IconButton aria-label="delete" className='swiper-button'  disabled={isPrevSecondDisabled} onClick={prevHandler2}>
              <ArrowBackIosIcon fontSize="large" sx={{ width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}} />
            </IconButton>
            <IconButton aria-label="delete" className='swiper-button'  disabled={isNextSecondDisabled} onClick={nextHandler2}>
              <ArrowForwardIosIcon fontSize="large" sx={{ width:'var(--equal-hw0_75)', height:'var(--equal-hw0_75)',ml:'0.25rem'}} />
            </IconButton>
          </Stack>
          }
          title={<Typography variant="h6">Training Videos</Typography>}
        /> */}
        {/* <Swiper
          ref={swiperRef2}
          onSwiper={(swiper) => setSwiperRef2(swiper)}
          // install Swiper modules
          modules={[Navigation, Pagination, A11y]}
          breakpoints={{
            599: {
              slidesPerView: 1,
              spaceBetween: 30
            },
            899: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            1199: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            1399: {
              slidesPerView: 4,
              spaceBetween: 20
            },
            1599: {
              slidesPerView: 4,
              spaceBetween: 40
            }
          }}
          navigation
          // showsPagination={false} 
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log('slide change')}
        >

          {
            trainingData.map((path, index) => {
              return (
                <SwiperSlide key={index}>
                <Link className="disable-link-styles" to="/Videos">
                <Card sx={{ padding:'2.5%', width:'100%'}} lg={4} md={6} sm={12}>
                    {(coinsWeHave < path.cost) ?
                    (<CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%', filter:'grayscale(100%)' }} 
                      component='video'
                      
                      poster={path.video}
                      src={path.videos}
                    />) :
                    (<CardMedia
                      sx={{ borderRadius: 1,
                          width: '100%', }} 
                      component='video'
                      poster={path.video}
                      src={path.videos}
                    />)
                    }
                    { 
                    (coinsWeHave < path.cost) ? 
                      (<Box className="overlay-lockicon-banner" sx={{display:'flex', justifyContent:'center',alignItems:"center"}}>
                         <LockOutlinedIcon fontSize='large' className="lock-icon"/></Box>)  : 
                      (<Fab 
                        className="play-icon" 
                        sx={{
                        position: 'absolute',
                        top: '35%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'}} 
                        color="primary" 
                        aria-label="play arrow">
                      <PlayArrowIcon />
                      </Fab>) 
                    }

                    <CardContent sx={{padding:".5rem 0rem"}}>
                      <Typography variant="body1" fontWeight="var(--font-weight-5)">
                        {path.title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {path.subtitle}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{height:'2rem', justifyContent:'space-between', p:'0'}}>
                      <Box color="var(--color-mui-primary-blue)" display="inline-flex" sx={{alignItems:'center'}}>
                        <Typography variant="body2">Redeem for</Typography>
                        <Box display="inline-flex" sx={{ width:"var(--equal-hw1)",height:"var(--equal-hw1)",scale:'2.4', m:'0rem .3rem'}}><img src={AnimatedCoin} alt="coin"/></Box>
                        <Typography variant="body2">{path.cost}</Typography>
                      </Box>
                      <Box>
                      { (coinsWeHave < path.cost) ? (<Typography display="inline-flex" variant="body2">{coinsWeHave}/{path.cost}</Typography>) : (<Button variant="contained">REDEEM</Button>)}
                      </Box>
                    </CardActions>
                  </Card>
                  </Link>
                </SwiperSlide>
              )
            })
          }
        </Swiper> */}
      {/* </Card> */}
        <Box sx={{mt:'1rem'}}>
          {/* {console.log(v,categories[v])} */}
          <ArrowFunction data={trainingData} renderFunc={renderFunc2} heading={"Training Videos"} subHeading={"You can redeem earned coins and badges for these paid training videos"} />
        </Box>
    </>
  )
}

export default EarnRedeemVideos

